import React, { useEffect } from "react";
import "./SubscribersProfileOrdersListMobile.scss";

import InfiniteLoading from "../../../componentsDashboard/ShipperAcceptedOrders/Components/UI/InfiniteLoading/InfiniteLoading";
import NoData from "../../../componentsDashboard/ShipperAcceptedOrders/Components/UI/NoData/NoData";
import SubscribersProfileOrdersListMobileItem from "./SubscribersProfileOrdersListMobileItem/SubscribersProfileOrdersListMobileItem";
import Pagination from "../../../componentsDashboard/Form/Pagination/Pagination";

const SubscribersProfileOrdersListMobile = ({
  items,
  loading,
  page,
  setPage,
  lastPage,
  currentPage,
  total,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);
  return (
    <div id='SubscribersOrderListMobile'>
      {loading && <InfiniteLoading />}
      {total === 0 ? (
        <NoData />
      ) : (
        items && (
          <div className='SubscribersOrderListMobileItems'>
            {items?.map((item) => (
              <SubscribersProfileOrdersListMobileItem item={item} key={item?.id} />
            ))}
          </div>
        )
      )}
      {lastPage && <Pagination value={page} setValue={setPage} pageCount={lastPage} />}
    </div>
  );
};

export default SubscribersProfileOrdersListMobile;
