import React from "react";
import "./SubscribersProfileOrdersListMobileItem.scss";

import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { IoMdRadioButtonOn } from "react-icons/io";
import { MdLocationPin } from "react-icons/md";
import moment from "moment";
import "moment/locale/ka";
import "moment/locale/en-gb";
import "moment/locale/ru";
import "moment/locale/uz";

import Character from "../../../../componentsDashboard/UI/Character/Character";

const SubscribersProfileOrdersListMobileItem = ({ item }) => {
  const { t } = useTranslation();
  const { token } = useParams();

  const { ISO } = useSelector((state) => state.lang);
  moment.locale(ISO);

  const cargos = item?.cargos?.[0] || [];
  const locations = cargos?.locations || [];

  const directionA = locations?.filter((location) => {
    return location.direction === "A";
  });
  const directionB = locations?.filter((location) => {
    return location.direction === "B";
  });

  const AddressDetailsItem = ({ content, icon }) => {
    return (
      <div className='AddressDetailsItem'>
        <div className='icon'>{icon}</div>
        <div className='content'>{content ? <Character title={content} max={45} /> : "-"}</div>
      </div>
    );
  };

  return (
    <div id='SubscribersProfileOrdersListMobileItem'>
      <Link to={`/subscribers/${token}/${item?.id}`} style={{ width: "100%" }}>
        <div className='topContainer'>
          <div className='routeContainer'>
            <div className='routeHeader'>
              <div className='from'>
                <div>
                  {directionA?.length > 0 &&
                    directionA.map((item, i) => (
                      <AddressDetailsItem
                        content={item.formatted_address}
                        key={i}
                        icon={<IoMdRadioButtonOn />}
                      />
                    ))}
                </div>
              </div>
            </div>
            <div className='distance'>
              {t("subscribers.tableList.Distance")}: {cargos.distance}{" "}
              {t("subscribers.tableList.KM")}
            </div>
            <div>
              {directionB?.length > 0 &&
                directionB.map((item, i) => (
                  <AddressDetailsItem
                    content={item.formatted_address}
                    key={i}
                    icon={<MdLocationPin style={{ color: "#00b56c" }} />}
                  />
                ))}
            </div>
          </div>
          <div className='cargoDetailsContainer'>
            <div className='cargoDetails'>
              <div className='title'>{t("subscribers.tableList.Trailer")}</div>
              <div className='content'>
                {cargos?.trailerType?.name} {cargos?.trailersize?.label}
              </div>
            </div>
            <div className='cargoDetails'>
              <div className='title'>{t("subscribers.tableList.Weight")}</div>
              <div className='content'>
                {cargos?.weight} {t("subscribers.tableList.KG")}
              </div>
            </div>
          </div>
        </div>
        <div className='offersContainer'>
          <p>
            {item?.last_shipment_status_update
              ? t("subscribers.tableList.EST. delivery date") +
                " " +
                moment(item?.last_shipment_status_update?.est).format("DD MMMM")
              : t("subscribers.tableList.Delivery date") +
                " " +
                moment(item?.dropoff_date).format("DD MMMM")}
          </p>
        </div>
      </Link>
    </div>
  );
};

export default SubscribersProfileOrdersListMobileItem;
