import _request from "../_request";

const getShareOrders = async (ISO, token) => {
  const config = {
    url: `/order/share/${token}`,
    ISO,
  };
  return _request(config);
};

const shareService = {
  getShareOrders,
};

export default shareService;
