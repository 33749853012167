import React, { useState, useEffect, Fragment } from "react";
import "./SubscribersMakeBidModal.scss";

import { useTranslation } from "react-i18next";
import { MdOutlineLocalOffer } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";

import {
  getCurrencies,
  getPaymentType,
  makeBid,
  resetMakeBid,
  resetUpdateBid,
  updateBid,
} from "../../../../store/subscribers/subscribersSlice";

import DModal from "../../../../componentsDashboard/Modal/DModal/DModal";
import DCurrencyInput from "../../../../componentsDashboard/Form/DCurrencyInput/DCurrencyInput";
import DSelect from "../../../../componentsDashboard/Form/DSelect/DSelect";
import DInputs from "../../../../componentsDashboard/Form/DInputs/DInputs";
import DPaymentSubOptions from "../../../../componentsDashboard/Form/DSelect/DPaymentSubOptions/DPaymentSubOptions";
import { useOrderPaymentSelectSubOptions } from "../../../../helper/useOrderPaymentSelectSubOptions";

const SubscribersMakeBidModal = ({ edit, token, item, isOpen, isClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    getCurrenciesSelect,
    getCurrenciesLoading,
    makeBidLoading,
    makeBidResult,
    makeBidError,
    updateBidLoading,
    updateBidResult,
    updateBidError,
    getPaymentTypeLoading,
    getPaymentTypeSelect,
  } = useSelector((state) => state.subscribers);
  const paymentSubOptions = useOrderPaymentSelectSubOptions();

  const [bid, setBid] = useState(edit ? item?.bid?.amount : "");
  const [currencyValue, setCurrencyValue] = useState(
    edit
      ? getCurrenciesSelect?.find((currency) => currency?.value?.id === item?.bid?.currency?.id)
      : getCurrenciesSelect?.[0] || null
  );

  const [payMethod, setPayMethod] = useState(
    edit
      ? getPaymentTypeSelect?.find(
          (payment) => payment?.value?.id === item?.bid?.payment_method?.type?.id
        )
      : getPaymentTypeSelect?.[0] || null
  );
  const [payMethodSub, setPayMethodSub] = useState(
    edit
      ? paymentSubOptions?.find(
          (payment) => payment?.value?.id === item?.bid?.payment_method?.option_type
        )
      : null
  );
  const [payMethodAmount, setPayMethodAmount] = useState(
    edit ? item?.bid?.payment_method?.amount : ""
  );
  const [payMethodAmountCurrency, setPayMethodAmountCurrency] = useState(null);
  const [payConsignationPeriod, setPayConsignationPeriod] = useState(
    edit ? item?.bid?.payment_method?.days : ""
  );

  useEffect(() => {
    if (edit) {
      if (getPaymentTypeSelect) {
        setPayMethod(
          getPaymentTypeSelect?.find(
            (payment) => payment?.value?.id === item?.bid?.payment_method?.type?.id
          )
        );
      }
      if (getCurrenciesSelect) {
        setCurrencyValue(
          getCurrenciesSelect?.find((currency) => currency?.value?.id === item?.bid?.currency?.id)
        );
      }
    } else {
      if (getPaymentTypeSelect) {
        setPayMethod(getPaymentTypeSelect[0]);
      }
      if (getCurrenciesSelect) {
        setCurrencyValue(getCurrenciesSelect?.[0]);
      }
    }
  }, [edit, getCurrenciesSelect, getPaymentTypeSelect, item]);

  useEffect(() => {
    if (!getCurrenciesSelect) {
      dispatch(getCurrencies(item?.branch_id));
    } else {
      setPayMethodAmountCurrency(
        edit
          ? getCurrenciesSelect?.find(
              (currency) => currency?.value?.id === item?.bid?.payment_method?.currency?.id
            )
          : getCurrenciesSelect?.[0] || null
      );
    }
  }, [
    dispatch,
    edit,
    getCurrenciesSelect,
    item?.bid?.payment_method?.currency?.id,
    item?.branch_id,
  ]);

  useEffect(() => {
    if (!getPaymentTypeSelect) {
      dispatch(getPaymentType());
    }
  }, [dispatch, getPaymentTypeSelect]);

  const submitHandler = () => {
    const data = {
      orderId: item?.id,
      signature: token,
      bidId: item?.bid?.id,
      data: {
        id: item?.id,
        amount: bid,
        currency_id: currencyValue?.value?.id,
        payment: {
          type_id: payMethod ? payMethod?.value?.id : null,
          days: payConsignationPeriod || null,
          option_type: payMethodSub ? payMethodSub?.value?.id : null,
          amount: payMethod && payMethod?.value?.id === 2 ? payMethodAmount : null,
          currency_id:
            payMethod && payMethod?.value?.id === 2 && payMethodAmountCurrency
              ? payMethodAmountCurrency?.value?.id
              : null,
        },
      },
    };

    edit ? dispatch(updateBid(data)) : dispatch(makeBid(data));
  };

  useEffect(() => {
    if (makeBidResult) {
      dispatch(resetMakeBid());
      isClose();
    }
    if (updateBidResult) {
      dispatch(resetUpdateBid());
      isClose();
    }
  }, [dispatch, isClose, makeBidResult, updateBidResult]);

  return (
    <DModal
      isOpen={isOpen}
      isClose={isClose}
      title={t("tms.modals.titles.Place your bid")}
      submitText={t("tms.modals.buttons.Submit")}
      icon={<MdOutlineLocalOffer />}
      loading={edit ? updateBidLoading : makeBidLoading}
      onClick={submitHandler}
    >
      <div id='LoadBoardMakeBidModal'>
        <p>{t("tms.modals.desc.You will see your bid status via my bids page")}</p>
        <div className='MakeBidModalGroup'>
          <DCurrencyInput
            id='MakeBidModalDCurrencyInput'
            value={bid}
            setValue={setBid}
            currencyValue={currencyValue}
            setCurrencyValue={setCurrencyValue}
            placeholder={t("tms.modals.placeholder.Enter your bid")}
            label={t("tms.modals.labels.Your bid")}
            required
            options={getCurrenciesSelect}
            loading={getCurrenciesLoading}
            error={edit ? updateBidError?.errors?.amount?.[0] : makeBidError?.errors?.amount?.[0]}
          />
          <DSelect
            required
            id='selectPaymentMethodMakeBidModal'
            label={t("tms.modals.labels.Payment method")}
            placeholder={t("tms.modals.placeholder.Payment method")}
            options={getPaymentTypeSelect ? getPaymentTypeSelect : []}
            value={payMethod}
            setValue={(e) => {
              setPayMethod(e);
              setPayMethodSub(null);
              setPayConsignationPeriod("");
              setPayMethodAmount("");
            }}
            loading={getPaymentTypeLoading}
            error={null}
          />

          {payMethod && (
            <Fragment>
              {payMethod?.value?.id === 2 && (
                <div className='groupItem'>
                  <DCurrencyInput
                    id='PaymentMethosAmount'
                    label={t("tms.forms.labels.PrepaymentAmount")}
                    placeholder={t("tms.forms.placeholder.Enter prepayment amount")}
                    value={payMethodAmount}
                    setValue={setPayMethodAmount}
                    currencyValue={payMethodAmountCurrency}
                    setCurrencyValue={setPayMethodAmountCurrency}
                    required
                    options={getCurrenciesSelect}
                    loading={getCurrenciesLoading}
                    error={
                      edit
                        ? updateBidError?.errors?.["payment.amount"]?.[0] ||
                          updateBidError?.errors?.["payment.currency_id"]?.[0]
                        : makeBidError?.errors?.["payment.amount"]?.[0] ||
                          makeBidError?.errors?.["payment.currency_id"]?.[0]
                    }
                  />
                </div>
              )}

              {(payMethod?.value?.id === 2 || payMethod?.value?.id === 3) && (
                <div className='groupItem SubTypes'>
                  <DPaymentSubOptions
                    value={payMethodSub}
                    setValue={(e) => {
                      setPayMethodSub(e);
                      setPayConsignationPeriod("");
                    }}
                    error={
                      edit
                        ? updateBidError?.errors?.["payment.option_type"]?.[0]
                        : makeBidError?.errors?.["payment.option_type"]?.[0]
                    }
                  />
                </div>
              )}

              {(payMethod?.value?.id === 2 || payMethod?.value?.id === 3) &&
                payMethodSub &&
                payMethodSub.value.id === "AFTER_DROP_OFF" && (
                  <div className='groupItem'>
                    <DInputs
                      required
                      id='payConsignationPeriodAddOrderPayment'
                      value={payConsignationPeriod}
                      setValue={setPayConsignationPeriod}
                      type='number'
                      placeholder={t("tms.forms.placeholder.Number of days")}
                      label={t("tms.forms.labels.Consignation period")}
                      error={
                        edit
                          ? updateBidError?.errors?.["payment.days"]?.[0]
                          : makeBidError?.errors?.["payment.days"]?.[0]
                      }
                    />
                  </div>
                )}
            </Fragment>
          )}
        </div>
      </div>
    </DModal>
  );
};

export default SubscribersMakeBidModal;
