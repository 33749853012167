import React from "react";
import "./ShareDetailContentTracking.scss";

import { useTranslation } from "react-i18next";

import ShareRoadprogressContainer from "./ShareRoadprogressContainer/ShareRoadprogressContainer";
import ShareTrackingInfoStatus from "./ShareTrackingInfoStatus/ShareTrackingInfoStatus";
import ShareTrackingTransport from "./ShareTrackingTransport/ShareTrackingTransport";
import ShareRailprogressContainer from "./ShareRailprogressContainer/ShareRailprogressContainer";
import ShareOceanprogressContainer from "./ShareOceanprogressContainer/ShareOceanprogressContainer";
import ShareAirprogressContainer from "./ShareAirprogressContainer/ShareAirprogressContainer";

const ShareDetailContentTracking = ({ order }) => {
  const { t } = useTranslation();

  return (
    <div id='ShareDetailContentTracking'>
      <div className='ShareDetailContentTrackingtitle'>
        <h3>{t("share.Tracking")}</h3>
      </div>

      <div className='ShareDetailContentTrackingContent'>
        <div className='ShareDetailContentTrackingInfo'>
          <ShareTrackingInfoStatus order={order} />
          <ShareTrackingTransport order={order} />
        </div>
        {order?.type_group?.id === 1 && <ShareRoadprogressContainer order={order} />}
        {order?.type_group?.id === 2 && <ShareOceanprogressContainer order={order} />}
        {order?.type_group?.id === 3 && <ShareAirprogressContainer order={order} />}
        {order?.type_group?.id === 4 && <ShareRailprogressContainer order={order} />}
      </div>
    </div>
  );
};

export default ShareDetailContentTracking;
