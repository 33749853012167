import React from "react";

import { Routes, Route } from "react-router-dom";

import {
  SubscribersDetail,
  SubscribersMain,
  SubscribersProfile,
  SubscribersLayout,
  Unsubscribe,
} from "../pages/Subscribers";

const Subscribers = () => {
  return (
    <Routes>
      <Route path='/subscribers/:token' element={<SubscribersLayout />}>
        <Route index element={<SubscribersMain />} />
        <Route path=':orderId' element={<SubscribersDetail />} />
        <Route path='profile' element={<SubscribersProfile />} />
        <Route path='unsubscribe' element={<Unsubscribe />} />
      </Route>
    </Routes>
  );
};

export default Subscribers;
