import React, { useState, useEffect, Fragment } from "react";
import "./BoardsList.scss";

import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import { getBoards, resetGetBoards } from "./../../../../../store/loadBoards/loadBoardsSlice";

import Wrapper from "../../../../../componentsAdditional/Wrapper";
import PageSwitcher from "../../../../../componentsDashboard/PageSwitcher/PageSwitcher";
import LoadsTable from "../../../../../componentsDashboard/LoadBoards/LoadsTable/LoadsTable";
import LoadingPage from "../../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";
import LoadBoardFilters from "../../../../../componentsDashboard/LoadBoards/LoadBoardFilters/LoadBoardFilters";
import SortSelect from "../../../../../componentsDashboard/FilterForms/SortSelect/SortSelect";
// import FavoriteRoutesButton from "../../../../../componentsDashboard/LoadBoards/FavoriteRoutes/FavoriteRoutesButton/FavoriteRoutesButton";
import MainTitle from "../../../../../componentsDashboard/MainTitle/MainTitle";
import PreferencesCover from "../../../../../componentsDashboard/LoadBoards/PreferencesCover/PreferencesCover";
import PreferencesAction from "../../../../../componentsDashboard/LoadBoards/PreferencesAction/PreferencesAction";
import PreferencesFilter from "../../../../../componentsDashboard/LoadBoards/PreferencesFilter/PreferencesFilter";

const BoardsList = () => {
  const { t } = useTranslation();

  const SORT_OPTIONS = [
    {
      label: t("tms.filters.labels.Type"),
      options: [
        {
          value: "1",
          label: t("tms.filters.labels.Newest"),
          data: { sortBy: "creation_date_desc" },
        },
        {
          value: "2",
          label: t("tms.filters.labels.Oldest"),
          data: { sortBy: "creation_date_asc" },
        },
      ],
    },
    {
      label: t("tms.filters.labels.Pick up date"),
      options: [
        {
          value: "3",
          label: t("tms.filters.labels.Earliest"),
          data: { sortBy: "pickup_date_asc" },
        },
        {
          value: "4",
          label: t("tms.filters.labels.Latest"),
          data: { sortBy: "pickup_date_desc" },
        },
      ],
    },
  ];
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const { getBoardsLoading, getBoardsResult, getBoardsMeta, biddingBoardResult, takeBoardResult } =
    useSelector((state) => state.loadBoards);
  const { updatePreferenceResult, storePreferenceResult } = useSelector(
    (state) => state.preferences
  );
  const { getPreferenceResult, getPreferenceLoading } = useSelector((state) => state.preferences);
  const { getOrderscountResponce } = useSelector((state) => state.summary);

  const [page, setPage] = useState(searchParams.get("page") ? searchParams.get("page") : 1);
  const [pickUp, setPickUp] = useState(null);
  const [dropOff, setDropOff] = useState(null);
  const [trailerType, setTrailerType] = useState(null);
  const [maxWeight, setMaxWeight] = useState("");
  const [date, setDate] = useState({});
  const [sort, setSort] = useState(null);
  const [preferences, setPreferences] = useState(
    searchParams.get("preferences") === "true" ? true : false
  );

  const [pickUpInput, setPickUpInput] = useState("");
  const [dropOffInput, setDropOffInput] = useState("");

  const [doItLater, setDoItLater] = useState(localStorage.getItem("doItLater") || false);

  const handleClearFilters = () => {
    if (pickUp) setPickUp(null);
    if (dropOff) setDropOff(null);
    if (trailerType) setTrailerType(null);
    if (maxWeight) setMaxWeight("");
    if (Object.keys(date).length > 0) setDate({});
    if (pickUpInput) setPickUpInput("");
    if (dropOffInput) setDropOffInput("");
  };

  useEffect(() => {
    if (doItLater) {
      localStorage.setItem("doItLater", doItLater);
    }
  }, [doItLater]);

  useEffect(() => {
    dispatch(
      getBoards({
        page,
        // has_bid: true,
        pickup_address: pickUp || "",
        dropoff_address: dropOff || "",
        trailer_type_id: trailerType?.value?.id || "",
        max_weight: maxWeight || "",
        start_date:
          Object.keys(date).length > 0 && date?.startDate
            ? moment(date.startDate).format("YYYY-MM-DD")
            : "",
        end_date:
          Object.keys(date).length > 0 && date?.endDate
            ? moment(date.endDate).format("YYYY-MM-DD")
            : "",
        sort: sort?.data?.sortBy,
        preferences,
      })
    );
    return () => {
      dispatch(resetGetBoards());
    };
  }, [
    dispatch,
    date,
    dropOff,
    maxWeight,
    page,
    pickUp,
    sort,
    trailerType,
    biddingBoardResult,
    takeBoardResult,
    storePreferenceResult,
    updatePreferenceResult,
    preferences,
  ]);

  return (
    <Wrapper documentTitle={t("tms.documentTitles.Load Boards")}>
      <section id='BoardsList'>
        {getPreferenceLoading ? (
          <LoadingPage />
        ) : (getPreferenceResult && Object?.keys(getPreferenceResult).length > 0) || doItLater ? (
          <Fragment>
            <MainTitle title={t("tms.titles.Load Boards")} />
            <PageSwitcher name='boards' />
            <LoadBoardFilters
              setPickUp={setPickUp}
              setDropOff={setDropOff}
              trailerType={trailerType}
              setTrailerType={setTrailerType}
              maxWeight={maxWeight}
              setMaxWeight={setMaxWeight}
              date={date}
              setDate={setDate}
              clearFilters={handleClearFilters}
              pickUpInput={pickUpInput}
              setPickUpInput={setPickUpInput}
              dropOffInput={dropOffInput}
              setDropOffInput={setDropOffInput}
            />
            {doItLater && getPreferenceResult && Object?.keys(getPreferenceResult).length === 0 && (
              <PreferencesAction />
            )}
            <div className='sortContainer'>
              <div className='leftContainer'>
                <div className='result'>
                  {`${t("tms.desc.Result")} ${getBoardsMeta?.total || "0"}/${
                    getOrderscountResponce?.loadboard_orders_count || "0"
                  }  ${t("tms.desc.order")}`}
                </div>
                {getPreferenceResult && Object?.keys(getPreferenceResult).length > 0 && (
                  <PreferencesFilter preferences={preferences} setPreferences={setPreferences} />
                )}
              </div>
              <div className='rightContainer'>
                <div className='sortButton'>
                  <SortSelect
                    value={sort}
                    setValue={(state) => {
                      setSort(state);
                    }}
                    options={SORT_OPTIONS}
                  />
                </div>
                {/* <div className='favRoutesButton'>
                  <FavoriteRoutesButton
                    setPickUpInput={setPickUpInput}
                    setDropOffInput={setDropOffInput}
                    setPickUp={setPickUp}
                    setDropOff={setDropOff}
                  />
                </div> */}
              </div>
            </div>
            {getBoardsLoading && <LoadingPage />}
            {getBoardsResult && !getBoardsLoading && (
              <LoadsTable
                items={getBoardsResult}
                page={page}
                setPage={setPage}
                pageCount={getBoardsMeta.last_page}
                total={getBoardsMeta.total}
              />
            )}
          </Fragment>
        ) : (
          <PreferencesCover setDoItLater={setDoItLater} />
        )}
      </section>
    </Wrapper>
  );
};

export default BoardsList;
