import React, { Fragment } from "react";
import "./SubscribersOrdersList.scss";

import { useTranslation } from "react-i18next";

import TableWrapper from "../../componentsDashboard/Form/TableFields/TableWrapper/TableWrapper";
import TableHeader from "../../componentsDashboard/Form/TableFields/TableHeader/TableHeader";
import SubscribersOrdersListItem from "./SubscribersOrdersListItem/SubscribersOrdersListItem";
import TableColumnWithSort from "../../componentsDashboard/Form/TableFields/TableColumnWithSort/TableColumnWithSort";
import LoadingPage from "../../componentsAdditional/Loading/LoadingPage/LoadingPage";

const SubscribersOrdersList = ({
  items,
  page,
  setPage,
  pageCount,
  total,
  sort,
  setSort,
  loading,
}) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      {loading ? (
        <LoadingPage />
      ) : (
        <Fragment>
          <div
            style={{
              color: "#4F575B",
              padding: "12px 0",
              fontSize: "13px",
              fontWeight: 600,
            }}
          >
            {total || 0} {t("subscribers.tableList.load result")}
          </div>
          {items && (
            <TableWrapper
              total={total}
              page={page}
              setPage={setPage}
              pageCount={pageCount}
              emptyText={t("tms.tables.noInfo.No orders")}
            >
              <TableHeader>
                <th>{t("subscribers.tableList.Route")}</th>
                <TableColumnWithSort
                  sort={sort}
                  setSort={setSort}
                  title={t("subscribers.tableList.Pick up date")}
                  asc={"pickup_date_asc"}
                  desc={"pickup_date_desc"}
                />
                <th>{t("subscribers.tableList.Trailer requirement")}</th>
                <TableColumnWithSort
                  sort={sort}
                  setSort={setSort}
                  title={t("subscribers.tableList.Weight")}
                  asc={"weight_asc"}
                  desc={"weight_desc"}
                />
                <th style={{ textAlign: "center" }}>{t("subscribers.tableList.Action button")}</th>
              </TableHeader>
              <tbody>
                {items.map((item) => (
                  <SubscribersOrdersListItem key={item.id} item={item} />
                ))}
              </tbody>
            </TableWrapper>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};
export default SubscribersOrdersList;
