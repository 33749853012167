import React from "react";
import "./LoadBoardsRouteField.scss";

import { HiSparkles } from "react-icons/hi2";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const LoadBoardsRouteField = ({ item, withoutLink }) => {
  const { t } = useTranslation();
  const { token } = useParams();

  const getLocation = (item, direction) => {
    const locationData = item && item.cargos[0].locations.filter((i) => i.direction === direction);

    const country = locationData && locationData[0]?.country;
    const city = locationData && locationData[locationData?.length - 1]?.city;

    return `${city ? city + ", " : ""}${country || ""}`;
  };
  const Alocation = getLocation(item, "A");
  const Blocation = getLocation(item, "B");
  const location = `${Alocation} - ${Blocation}`;

  return (
    <td id='LoadBoardsRouteField' className={withoutLink ? "withoutLink" : "link"}>
      {withoutLink ? (
        <div className='LoadBoardsRouteFieldcontent'>
          {item?.preference && (
            <span>
              <HiSparkles color='#5e2bff' fontSize={16} />
            </span>
          )}
          <div>
            <div className='route'>{location}</div>
            <div className='distance'>
              {t("subscribers.tableList.Distance")}: {item?.distance}{" "}
              {t("subscribers.tableList.KM")}
            </div>
          </div>
        </div>
      ) : (
        <Link to={`/subscribers/${token}/${item?.id}`}>
          <div className='LoadBoardsRouteFieldcontent'>
            {item?.preference && (
              <span>
                <HiSparkles color='#5e2bff' fontSize={16} />
              </span>
            )}
            <div>
              <div className='route'>{location}</div>
              <div className='distance'>
                {t("subscribers.tableList.Distance")}: {item?.distance}{" "}
                {t("subscribers.tableList.KM")}
              </div>
            </div>
          </div>
        </Link>
      )}
    </td>
  );
};

export default LoadBoardsRouteField;
