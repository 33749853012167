import React from "react";
import "./UnsubscribeButton.scss";
import ButtonLoader from "../../../componentsDashboard/Form/DButtons/ButtonLoader/ButtonLoader";
const UnsubscribeButton = ({ title, classButton, click, loader, color }) => {
  return (
    <button
      id='UnsubscribeButton'
      onClick={click}
      className={`unsubscribeBTN ${classButton}`}
      style={{ background: loader ? color : "" }}
    >
      {loader ? <ButtonLoader /> : title}
    </button>
  );
};

export default UnsubscribeButton;
