import React from "react";
import "./ToggleButton.scss";
import { MdCancel } from "react-icons/md";

const ToggleButton = ({ text, isActive, handleClick }) => {
  return (
    <div id='ToggleButton' className={`${isActive ? "active" : ""}`} onClick={handleClick}>
      <p>{text}</p>
      {isActive && (
        <div className='icon'>
          <MdCancel />
        </div>
      )}
    </div>
  );
};

export default ToggleButton;
