import React from "react";
import "./SubscribersOrderListMobileItem.scss";

import { HiSparkles } from "react-icons/hi2";
import { MdLocationPin, MdCalendarToday } from "react-icons/md";
import { IoMdRadioButtonOn } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

import Character from "../../../../componentsDashboard/UI/Character/Character";
import { useMoney } from "../../../../helper/useMoney";

const SubscribersOrderListMobileItem = ({ item }) => {
  const { t } = useTranslation();
  const { token } = useParams();
  const cargos = item?.cargos?.[0] || [];
  const locations = cargos?.locations || [];

  const directionA = locations?.filter((location) => {
    return location.direction === "A";
  });
  const directionB = locations?.filter((location) => {
    return location.direction === "B";
  });

  const bidAmountFormated = useMoney(item?.bid?.amount, item?.bid?.currency?.symbol);

  const AddressDetailsItem = ({ content, icon }) => {
    return (
      <div className='AddressDetailsItem'>
        <div className='icon'>{icon}</div>
        <div className='content'>{content ? <Character title={content} max={45} /> : "-"}</div>
      </div>
    );
  };

  return (
    <div id='SubscribersOrderListMobileItem'>
      <Link to={`/subscribers/${token}/${item?.id}`} style={{ width: "100%" }}>
        <div className='topContainer'>
          <div className='routeContainer'>
            <div className='routeHeader'>
              <div className='from'>
                <div>
                  {directionA?.length > 0 &&
                    directionA.map((item, i) => (
                      <AddressDetailsItem
                        content={item.formatted_address}
                        key={i}
                        icon={<IoMdRadioButtonOn />}
                      />
                    ))}
                </div>
              </div>
              <div className='sparklesIcon'>
                <HiSparkles />
              </div>
            </div>
            <div className='distance'>
              {t("subscribers.tableList.Distance")}: {cargos.distance}{" "}
              {t("subscribers.tableList.KM")}
            </div>
            <div>
              {directionB?.length > 0 &&
                directionB.map((item, i) => (
                  <AddressDetailsItem
                    content={item.formatted_address}
                    key={i}
                    icon={<MdLocationPin style={{ color: "#00b56c" }} />}
                  />
                ))}
            </div>
          </div>
          <div className='pickupDateContainer'>
            <div className='icon'>
              <MdCalendarToday />
            </div>
            <p>
              {t("subscribers.tableList.Pick up date")}: <span>{item.pickup_date}</span>
            </p>
          </div>
          <div className='cargoDetailsContainer'>
            <div className='cargoDetails'>
              <div className='title'>{t("subscribers.tableList.Trailer")}</div>
              <div className='content'>
                {cargos?.trailerType?.name} {cargos?.trailersize?.label}
              </div>
            </div>
            <div className='cargoDetails'>
              <div className='title'>{t("subscribers.tableList.Weight")}</div>
              <div className='content'>
                {cargos?.weight} {t("subscribers.tableList.KG")}
              </div>
            </div>
          </div>
        </div>
        {item?.bid && (
          <div className='offersContainer'>
            <p>
              {t("subscribers.tableList.You offered")} {bidAmountFormated}
            </p>
          </div>
        )}
      </Link>
    </div>
  );
};

export default SubscribersOrderListMobileItem;
