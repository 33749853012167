import React, { Fragment, useState } from "react";
import "./PreferencesFilter.scss";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { HiSparkles } from "react-icons/hi2";
import { FiX } from "react-icons/fi";
import { MdModeEditOutline } from "react-icons/md";

import PreferencesModal from "../Modals/PreferencesModal/PreferencesModal";

const PreferencesFilter = ({
  preferences,
  setPreferences,
  withoutEdit,
  handleClick,
  orderCount,
}) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { getPreferenceResult } = useSelector((state) => state.preferences);

  const [showPreferencesModal, setShowPreferencesModal] = useState(false);

  return (
    <Fragment>
      <div id='PreferencesFilter'>
        <div
          className='filter'
          style={{ backgroundColor: preferences ? "#5e2bff" : "#FFFFFF" }}
          onClick={
            handleClick
              ? () => handleClick()
              : () => {
                  setPreferences(!preferences);
                  searchParams.set("preferences", !preferences);
                  setSearchParams(searchParams);
                }
          }
        >
          <div className='icon'>
            <HiSparkles color={preferences ? "#FFFFFF" : "#5e2bff"} />
          </div>
          <div style={{ color: preferences ? "#FFFFFF" : "#4f575b" }} className='title'>
            {t("tms.filters.labels.Show only preferred types")}
          </div>
          {!preferences && (
            <div className='qty'>{orderCount || getPreferenceResult?.order_count}</div>
          )}
          {preferences && (
            <div className='icon'>
              <FiX color='#FFFFFF' />
            </div>
          )}
        </div>
        {withoutEdit ? (
          ""
        ) : (
          <div className='editButton' onClick={() => setShowPreferencesModal(true)}>
            <MdModeEditOutline />
          </div>
        )}
      </div>
      <PreferencesModal
        isOpen={showPreferencesModal}
        isClose={() => setShowPreferencesModal(false)}
        edit
      />
    </Fragment>
  );
};

export default PreferencesFilter;
