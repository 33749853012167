import React from "react";
import "./ShareLanguage.scss";

import { useDispatch, useSelector } from "react-redux";
import { MdOutlineLanguage } from "react-icons/md";

import { setLang } from "../../../../store/additional/lang/langSlice";
import { updateUserLanguage } from "../../../../store/user/userSlice";

const ShareLanguage = () => {
  const { getLanguagesResult, ISO } = useSelector((state) => state.lang);
  const dispatch = useDispatch();

  const RenderItem = ({ item }) => {
    return (
      <div
        onClick={() => {
          dispatch(updateUserLanguage({ language: item.key }));
          setTimeout(() => {
            dispatch(setLang(item.key));
          }, 500);
        }}
        id='ShareLanguageItem'
      >
        <p>{item.native}</p>
      </div>
    );
  };

  return (
    <div id='ShareLanguage'>
      <div className='ShareLanguageButton'>
        <div className='icon'>
          <MdOutlineLanguage />
        </div>

        <p>{ISO && ISO.toUpperCase()}</p>
      </div>
      <ul className='ShareLanguageContent'>
        {getLanguagesResult &&
          getLanguagesResult.map((item) => <RenderItem item={item} key={item.key} />)}
      </ul>
    </div>
  );
};

export default ShareLanguage;
