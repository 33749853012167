import React from "react";
import "./TableColumnWithSort.scss";

import { useSearchParams } from "react-router-dom";

import { RxCaretSort, RxCaretUp, RxCaretDown } from "react-icons/rx";

const TableColumnWithSort = ({ sort, setSort, title, asc, desc }) => {
  let [searchParams, setSearchParams] = useSearchParams();

  return (
    <th id='TableColumnWithSort'>
      <div
        className='TableColumnWithSortContainer'
        onClick={() => {
          const nextSort = sort === desc ? asc : desc;
          setSort(nextSort);
          searchParams.set("sort", nextSort);
          setSearchParams(searchParams);
        }}
      >
        {title}
        {sort === desc && <RxCaretDown />}
        {sort === asc && <RxCaretUp />}
        {sort !== asc && sort !== desc && <RxCaretSort />}
      </div>
    </th>
  );
};

export default TableColumnWithSort;
