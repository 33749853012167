import React from "react";
import "./LoadBoardsDeliveredField.scss";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";
import "moment/locale/ka";
import "moment/locale/en-gb";
import "moment/locale/ru";
import "moment/locale/uz";

const LoadBoardsDeliveredField = ({ item }) => {
  const { t } = useTranslation();
  const { ISO } = useSelector((state) => state.lang);
  moment.locale(ISO);

  return (
    <td id='LoadBoardsDeliveredField'>
      <div className={`dropoffDate ${item?.last_shipment_status_update ? "est" : ""}`}>
        {item?.last_shipment_status_update
          ? `EST. delivery ${moment(item?.last_shipment_status_update?.est).format("DD MMMM")}`
          : item?.dropoff_date
          ? moment(item?.dropoff_date).format("DD MMMM")
          : "-"}
      </div>
      <div className='pickupDate'>
        {t("subscribers.tableList.Pick up") + " " + moment(item?.pickup_date).format("DD MMMM")}
      </div>
    </td>
  );
};

export default LoadBoardsDeliveredField;
