import React from "react";
import "./LoadBoardsPickupDateField.scss";

import { useSelector } from "react-redux";

import moment from "moment";

import { useFromNowDate } from "../../../../../helper/useFromNowDate";

const LoadBoardsPickupDateField = ({ item }) => {
  const { ISO } = useSelector((state) => state.lang);
  moment.locale(ISO);

  const pickupDate = moment(item?.pickup_date, "YYYY-MM-DD").format("DD MMMM");
  const leftBefore = useFromNowDate(item?.pickup_date, ISO);

  return (
    <td id='LoadBoardsPickupDateField'>
      <div className='LoadBoardsPickupDateFieldcontent'>
        <div className='date'>{pickupDate}</div>
        <p>{leftBefore}</p>
      </div>
    </td>
  );
};

export default LoadBoardsPickupDateField;
