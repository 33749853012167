import React, { Fragment, useEffect, useState } from "react";
import "./SubscribersProfile.scss";

import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineArrowForward } from "react-icons/md";
import moment from "moment";

import { getSubscribersConfirmedOrders } from "../../../store/subscribers/subscribersSlice";
import useQuerySerialization from "../../../helper/useQuerySerialization";

import SubscriberPersonalInfo from "../../../componentsSubscribers/SubscribersProfile/SubscriberPersonalInfo/SubscriberPersonalInfo";
import MainTitle from "../../../componentsDashboard/MainTitle/MainTitle";
import SubscribersFilters from "../../../componentsSubscribers/SubscribersFilters/SubscribersFilters";
import SubscribersProfileOrdersList from "../../../componentsSubscribers/SubscribersProfile/SubscribersProfileOrdersList/SubscribersProfileOrdersList";
import SubscribersProfileOrdersListMobile from "../../../componentsSubscribers/SubscribersProfile/SubscribersProfileOrdersListMobile/SubscribersProfileOrdersListMobile";
import DButton from "../../../componentsDashboard/Form/DButtons/DButton/DButton";

const SubscribersProfile = () => {
  const { t } = useTranslation();
  const { token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const { deserializeArray } = useQuerySerialization();

  const {
    getSubscribersConfirmedOrdersResult,
    getSubscribersConfirmedOrdersLoading,
    getPreferencesResult,
    getTrailerTypesResult,
    getRoutesResult,
  } = useSelector((state) => state.subscribers);

  const trailersOptions = getTrailerTypesResult?.map((trailerType) => ({
    value: { id: trailerType.id },
    label: trailerType.name,
  }));

  const routesOptions = getRoutesResult?.map((route) => ({
    value: { id: route.id },
    label: route.name,
  }));

  const [page, setPage] = useState(searchParams.get("page") ? searchParams.get("page") : 1);

  const [pickupDropoffInGeorgia, setPickupDropoffInGeorgia] = useState(
    searchParams.get("pickupDropoffInGeorgia") === "true" ? true : false
  );
  const routesSearchParamName = "routes";
  const [selectedRoutes, setSelectedRoutes] = useState(
    deserializeArray(searchParams.get(routesSearchParamName)) || []
  );
  const trailerSearchParamName = "trailers";
  const [selectedTrailers, setSelectedTrailers] = useState(
    deserializeArray(searchParams.get(trailerSearchParamName)) || []
  );

  const [pickupCountry, setPickupCountry] = useState("");
  const [dropoffCountry, setDropoffCountry] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [sort, setSort] = useState(searchParams.get("sort") ? searchParams.get("sort") : null);

  const onClear = () => {
    if (pickupDropoffInGeorgia) {
      setPickupDropoffInGeorgia(false);
      searchParams.delete("pickupDropoffInGeorgia");
    }
    if (selectedRoutes?.length > 0) {
      setSelectedRoutes([]);
      searchParams.delete(routesSearchParamName);
    }
    if (selectedTrailers?.length > 0) {
      setSelectedTrailers([]);
      searchParams.delete(trailerSearchParamName);
    }
    if (pickupCountry) {
      setPickupCountry("");
    }
    if (dropoffCountry) {
      setDropoffCountry("");
    }
    if (startDate) {
      setStartDate(null);
    }
    if (endDate) {
      setEndDate(null);
    }
    if (sort) {
      setSort(null);
      searchParams.delete("sort");
    }
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (getPreferencesResult) {
      dispatch(
        getSubscribersConfirmedOrders({
          signature: token,
          page: page,
          pickup_address: pickupCountry,
          dropoff_address: dropoffCountry,
          pickup_date_max: endDate && moment(endDate).format("YYYY-MM-DD"),
          pickup_date_min: startDate && moment(startDate).format("YYYY-MM-DD"),
          registration_country:
            pickupDropoffInGeorgia && getPreferencesResult?.registrationCountry?.code,
          routes: selectedRoutes?.map((item) => item.value.id),
          trailer_types: selectedTrailers?.map((item) => item.value.id),
          sort_by: sort,
        })
      );
    }
  }, [
    dispatch,
    dropoffCountry,
    endDate,
    getPreferencesResult,
    page,
    pickupCountry,
    pickupDropoffInGeorgia,
    selectedRoutes,
    selectedTrailers,
    startDate,
    token,
    sort,
  ]);

  return (
    <Fragment>
      <section id='SubscribersProfile'>
        <div className='SubscribersProfile-container wrapper'>
          <div className='signUpSuggestionContainer'>
            <div className='group'>
              <h3>{t("subscribers.board.CRM for transporters")}</h3>
              <div className='badge'>{t("subscribers.board.Free")}</div>
            </div>
            <div className='group'>
              <DButton
                title={<MdOutlineArrowForward />}
                onClick={() => {
                  navigate("/register");
                }}
              />
            </div>
          </div>
          <div className='personInfoBox'>
            <SubscriberPersonalInfo />
          </div>

          <MainTitle title={t("subscribers.profile.My shipments")} />
          <SubscribersFilters
            pickupDropoffInGeorgia={pickupDropoffInGeorgia}
            setPickupDropoffInGeorgia={setPickupDropoffInGeorgia}
            selectedRoutes={selectedRoutes}
            setSelectedRoutes={setSelectedRoutes}
            routesOptions={routesOptions}
            routesSearchParamName={routesSearchParamName}
            selectedTrailers={selectedTrailers}
            setSelectedTrailers={setSelectedTrailers}
            trailersOptions={trailersOptions}
            trailerSearchParamName={trailerSearchParamName}
            pickupCountry={pickupCountry}
            setPickupCountry={setPickupCountry}
            dropoffCountry={dropoffCountry}
            setDropoffCountry={setDropoffCountry}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            sort={sort}
            setSort={setSort}
            onClear={onClear}
          />
          <div className='tableContainer'>
            <SubscribersProfileOrdersList
              page={page}
              setPage={setPage}
              items={getSubscribersConfirmedOrdersResult?.data}
              total={getSubscribersConfirmedOrdersResult?.meta?.total}
              pageCount={getSubscribersConfirmedOrdersResult?.meta?.last_page}
              sort={sort}
              setSort={setSort}
              loading={getSubscribersConfirmedOrdersLoading}
            />
          </div>
          <div className='mobileOrdersList'>
            <SubscribersProfileOrdersListMobile
              items={getSubscribersConfirmedOrdersResult?.data}
              loading={getSubscribersConfirmedOrdersLoading}
              page={page}
              setPage={setPage}
              lastPage={getSubscribersConfirmedOrdersResult?.meta?.last_page}
              currentPage={getSubscribersConfirmedOrdersResult?.meta?.current_page}
              total={getSubscribersConfirmedOrdersResult?.meta?.total}
            />
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default SubscribersProfile;
