import React from "react";

const KGIcon = () => {
  return (
    <span>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
      >
        <path
          d='M8.00016 2C8.70741 2 9.38568 2.28095 9.88578 2.78105C10.3859 3.28115 10.6668 3.95942 10.6668 4.66667C10.6668 5.15333 10.5402 5.60667 10.3068 6H12.0002C12.6335 6 13.1668 6.44667 13.3002 7.04C14.6402 12.38 14.6668 12.52 14.6668 12.6667C14.6668 13.0203 14.5264 13.3594 14.2763 13.6095C14.0263 13.8595 13.6871 14 13.3335 14H2.66683C2.31321 14 1.97407 13.8595 1.72402 13.6095C1.47397 13.3594 1.3335 13.0203 1.3335 12.6667C1.3335 12.52 1.36016 12.38 2.70016 7.04C2.8335 6.44667 3.36683 6 4.00016 6H5.6935C5.45518 5.59627 5.33077 5.13548 5.3335 4.66667C5.3335 3.95942 5.61445 3.28115 6.11454 2.78105C6.61464 2.28095 7.29292 2 8.00016 2ZM8.00016 3.33333C7.64654 3.33333 7.3074 3.47381 7.05735 3.72386C6.80731 3.97391 6.66683 4.31304 6.66683 4.66667C6.66683 5.02029 6.80731 5.35943 7.05735 5.60948C7.3074 5.85952 7.64654 6 8.00016 6C8.35378 6 8.69292 5.85952 8.94297 5.60948C9.19302 5.35943 9.3335 5.02029 9.3335 4.66667C9.3335 4.31304 9.19302 3.97391 8.94297 3.72386C8.69292 3.47381 8.35378 3.33333 8.00016 3.33333ZM4.00016 7.33333V12.6667H5.3335V11L6.00016 11.6667V12.6667H7.3335V11.3333L6.00016 10L7.3335 8.66667V7.33333H6.00016V8.33333L5.3335 9V7.33333H4.00016ZM10.0002 7.33333C9.26016 7.33333 8.66683 7.92667 8.66683 8.66667V11.3333C8.66683 12.0733 9.26016 12.6667 10.0002 12.6667H12.0002V9.33333H10.6668V11.3333H10.0002V8.66667H12.0002V7.33333H10.0002Z'
          fill='#848A8D'
        />
      </svg>
    </span>
  );
};

export default KGIcon;
