import React, { Fragment, useState, useEffect } from "react";
import "./ShareOrders.scss";

import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { getShareOrders, resetGetShareOrders } from "./../../../store/share/shareSlice";

import LoadingPage from "../../../componentsAdditional/Loading/LoadingPage/LoadingPage";

import Wrapper from "../../../componentsAdditional/Wrapper";
import EmptyList from "../../../componentsDashboard/UI/EmptyList/EmptyList";
import ShareHeader from "../../../componentsShare/ShareHeader/ShareHeader";
import ShareDetail from "../../../componentsShare/ShareDetail/ShareDetail";
import ShareSidebar from "../../../componentsShare/ShareSidebar/ShareSidebar";

const ShareOrders = () => {
  const { t } = useTranslation();
  const { token } = useParams();
  const dispatch = useDispatch();

  const { getShareOrdersLoading, getShareOrdersResponce, getShareOrdersError } = useSelector(
    (state) => state.share
  );

  const [openSidebar, setOpenSidebar] = useState(false);
  const [activeOrder, setActiveOrder] = useState(null);

  useEffect(() => {
    if (getShareOrdersResponce && getShareOrdersResponce.length > 0) {
      setActiveOrder(getShareOrdersResponce[0]);
    }
  }, [getShareOrdersResponce]);

  useEffect(() => {
    dispatch(getShareOrders(token));
    return () => {
      dispatch(resetGetShareOrders());
    };
  }, [dispatch, token]);

  return (
    <Wrapper documentTitle={t("share.Shipment tracking")}>
      {getShareOrdersLoading && <LoadingPage />}
      {getShareOrdersError ||
        (getShareOrdersResponce && getShareOrdersResponce.length === 0 && (
          <EmptyList
            image={"/assets/images/dashboard/cover1.svg"}
            title={t("share.Tracking Unavailable at the Moment")}
          />
        ))}

      {getShareOrdersResponce && getShareOrdersResponce.length > 0 && activeOrder && (
        <Fragment>
          <ShareHeader
            openSidebar={openSidebar}
            setOpenSidebar={setOpenSidebar}
            items={getShareOrdersResponce}
            activeOrder={activeOrder}
            setActiveOrder={setActiveOrder}
          />
          <main>
            <section id='ShareOrders'>
              <div id='ShareOrdersSidebar'>
                <ShareSidebar
                  openSidebar={openSidebar}
                  setOpenSidebar={setOpenSidebar}
                  items={getShareOrdersResponce}
                  activeOrder={activeOrder}
                  setActiveOrder={setActiveOrder}
                />
              </div>
              <ShareDetail order={activeOrder} />
            </section>
          </main>
        </Fragment>
      )}
    </Wrapper>
  );
};

export default ShareOrders;
