import React, { Fragment, useEffect, useState } from "react";
import "./UnsubscribeComponent.scss";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { MdInfoOutline } from "react-icons/md";
import { IoCheckmarkOutline } from "react-icons/io5";

import UnsubscribeButton from "../UnsubscribeButton/UnsubscribeButton";
import {
  resetResubscribe,
  resetSetNotificationTime,
  resetUnsubscribe,
  resubscribe,
  setNotificationTime,
  unsubscribe,
} from "../../../store/subscribers/subscribersSlice";

import DModal from "../../../componentsDashboard/Modal/DModal/DModal";
import DSelect from "../../../componentsDashboard/Form/DSelect/DSelect";
import DButton from "../../../componentsDashboard/Form/DButtons/DButton/DButton";

const UnsubscribeComponent = () => {
  const { t } = useTranslation();
  const { token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [unModal, setUnModal] = useState(false);
  const [showResubscribe, setShowResubscribe] = useState(false);
  const [frequency, setFrequency] = useState();
  const [time, setTime] = useState();
  const [communication, setCommunication] = useState();
  const freqyencyOption = [
    { value: "daily", label: t("subscribers.unsubscribe.Daily"), id: 0 },
    { value: "every_2_days", label: t("subscribers.unsubscribe.Every 2 Days"), id: 1 },
    { value: "once_a_week", label: t("subscribers.unsubscribe.Once a Week"), id: 7 },
  ];
  const timeOption = [
    { value: "09:00", label: "09:00", id: 10 },
    { value: "10:00", label: "10:00", id: 11 },
    { value: "11:00", label: "11:00", id: 12 },
    { value: "12:00", label: "12:00", id: 13 },
    { value: "13:00", label: "13:00", id: 14 },
    { value: "14:00", label: "14:00", id: 15 },
    { value: "15:00", label: "15:00", id: 16 },
    { value: "16:00", label: "16:00", id: 17 },
    { value: "17:00", label: "17:00", id: 18 },
    { value: "18:00", label: "18:00", id: 19 },
    { value: "19:00", label: "19:00", id: 20 },
    { value: "20:00", label: "20:00", id: 21 },
    { value: "21:00", label: "21:00", id: 22 },
    { value: "22:00", label: "22:00", id: 23 },
    { value: "23:00", label: "23:00", id: 24 },
  ];
  const CommunicationOption = [
    { value: "email", label: t("subscribers.unsubscribe.Email"), id: 1 },
    { value: "whatsapp", label: t("subscribers.unsubscribe.Whatsapp"), id: 2 },
    { value: "both", label: t("subscribers.unsubscribe.Both"), id: 3 },
  ];

  const {
    unsubscribeResult,
    unsubscribeLoading,
    resubscribeResult,
    resubscribeLoading,
    setNotificationTimeResult,
    setNotificationTimeLoading,
  } = useSelector((state) => state.subscribers);

  const unsubscribeHandler = () => {
    dispatch(unsubscribe(token));
  };

  useEffect(() => {
    if (unsubscribeResult) {
      setUnModal(false);
      setShowResubscribe(true);
    }
    return () => {
      dispatch(resetUnsubscribe());
    };
  }, [dispatch, unsubscribeResult]);

  const resubscribeHandler = () => {
    dispatch(resubscribe(token));
  };

  useEffect(() => {
    if (resubscribeResult) {
      navigate(`/subscribers/${token}`);
    }
    return () => {
      dispatch(resetResubscribe());
    };
  }, [dispatch, resubscribeResult, token, navigate]);

  const SetNotificationTimeHandler = () => {
    const data = {
      signature: token,
      data: {
        frequency: frequency?.id,
        contact_channel: communication?.value,
        suitable_time: time?.value,
      },
    };
    dispatch(setNotificationTime(data));
  };

  useEffect(() => {
    if (setNotificationTimeResult) {
      setFrequency("");
      setTime("");
      setCommunication("");
      alert(
        t("subscribers.unsubscribe.Your notification preferences have been updated successfully!")
      );
      navigate(`/subscribers/${token}`);
    }
    return () => {
      dispatch(resetSetNotificationTime());
    };
  }, [dispatch, setNotificationTimeResult, t, navigate, token]);

  return (
    <Fragment>
      <div id='UnsubscribeComponent'>
        <div className='unsubscribeContainer'>
          <div className='headImage'>
            <div className='imgBox'>
              <div
                className='img'
                style={{
                  backgroundImage: `url(${`/assets/images/subscribers/Inbox-cleanup-amico-1.svg`})`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
            </div>
          </div>
          <div className='bodyContent'>
            {!showResubscribe && (
              <div className='unsubscribeContent'>
                <h2>{t("subscribers.unsubscribe.Do you really want to unsubscribe?")}</h2>
                <div className='warnText'>
                  <div className='icon'>
                    <MdInfoOutline />
                  </div>
                  <p>
                    {t(
                      "subscribers.unsubscribe.Before you go, you can customise your notifications instead of fully unsubscribing"
                    )}
                  </p>
                </div>
                <div className='unsubscribeSelectsBox'>
                  <div className='unsubscribeSelect'>
                    <div className='selectFrequency selectItem'>
                      <DSelect
                        placeholder={t("subscribers.unsubscribe.Frequency")}
                        value={frequency}
                        setValue={(state) => setFrequency(state)}
                        error={null}
                        options={freqyencyOption ? freqyencyOption : []}
                        search={false}
                        label={t("subscribers.unsubscribe.Frequency")}
                        required
                      />
                    </div>
                    <div className='selectSuitableTime selectItem'>
                      <DSelect
                        placeholder={t("subscribers.unsubscribe.Suitable time")}
                        value={time}
                        setValue={(state) => setTime(state)}
                        error={null}
                        options={timeOption ? timeOption : []}
                        search={false}
                        label={t("subscribers.unsubscribe.Suitable time")}
                      />
                    </div>
                    <div className='selectCommunicationMethod selectItem'>
                      <DSelect
                        placeholder={t("subscribers.unsubscribe.Communication method")}
                        value={communication}
                        setValue={(state) => setCommunication(state)}
                        error={null}
                        options={CommunicationOption ? CommunicationOption : []}
                        search={false}
                        label={t("subscribers.unsubscribe.Communication method")}
                        required
                      />
                    </div>
                  </div>
                  <DButton
                    title={<IoCheckmarkOutline style={{ color: "#fff", fontSize: "24px" }} />}
                    onClick={() => SetNotificationTimeHandler()}
                    disabled={!frequency?.value || !communication?.value}
                    loading={setNotificationTimeLoading}
                  />
                </div>
                <div className='textItalicConfirm'>
                  {t(
                    "subscribers.unsubscribe.If you still want to unsubscribe completely, confirm below"
                  )}
                </div>
                <div className='unShipmentBox'>
                  <h2>
                    {t("subscribers.unsubscribe.If you unsubscribe, you will no longer receive")}
                  </h2>
                  <div className='ship-1-box'>
                    <div className='icon'>🚛</div>
                    <p>{t("subscribers.unsubscribe.Daily Shipment Opportunities")}</p>
                  </div>
                  <div className='ship-1-box'>
                    <div className='icon'>📍</div>
                    <p>{t("subscribers.unsubscribe.Real-Time Shipment Alerts")}</p>
                  </div>
                </div>
                <div className='unsubscribeButton'>
                  <UnsubscribeButton
                    classButton={"unsBtn"}
                    title={t("subscribers.unsubscribe.Unsubscribe")}
                    click={() => setUnModal(true)}
                    loader={unsubscribeLoading}
                    color={"#DA1414"}
                  />
                </div>
              </div>
            )}
            {showResubscribe && (
              <div className='reSubscribeContent'>
                <h2>{t("subscribers.unsubscribe.You’ve Unsubscribed")}</h2>
                <div className='text'>
                  {t(
                    "subscribers.unsubscribe.You have successfully unsubscribed from our shipment notifications"
                  )}
                </div>
                <div className='unsubscribeButton'>
                  <UnsubscribeButton
                    classButton={"reSubBtn"}
                    title={t("subscribers.unsubscribe.Re-Subscribe Now")}
                    click={() => resubscribeHandler()}
                    loader={resubscribeLoading}
                    color={"#1A2023"}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {unModal && (
        <DModal
          isOpen={unModal}
          isClose={() => setUnModal(false)}
          title={t("subscribers.unsubscribe.Unsubscribe")}
          submitText={t("subscribers.unsubscribe.accept")}
          status='danger'
          onClick={() => {
            unsubscribeHandler();
          }}
          loading={unsubscribeLoading}
        >
          <div id='UnsubscribeConfirmationModal'>
            <div className='unsubscribeModalText'>
              <p>{t("subscribers.unsubscribe.Are you sure you want to unsubscribe?")}</p>
            </div>
          </div>
        </DModal>
      )}
    </Fragment>
  );
};

export default UnsubscribeComponent;
