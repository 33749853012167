import React, { Fragment } from "react";
import "./ShareHeader.scss";

import Logo from "../../componentsAdditional/SVG/Cargon/Logo";
import CircularLogoSmall from "./../../componentsAdditional/SVG/Cargon/CircularLogoSmall";

import ShareLanguage from "./Components/ShareLanguage/ShareLanguage";
import ShareBurgerIcon from "./Components/ShareBurgerIcon/ShareBurgerIcon";
import ShareNavMobile from "./Components/ShareNavMobile/ShareNavMobile";

const ShareHeader = ({ openSidebar, setOpenSidebar, items, activeOrder, setActiveOrder }) => {
  return (
    <Fragment>
      <header id='ShareHeader'>
        <div className='ShareHeaderContainer'>
          <ShareBurgerIcon value={openSidebar} setValue={setOpenSidebar} />
          <div className='ShareHeaderContainerLogo'>
            <div>
              <div className='fullLogo'>
                <Logo />
              </div>
              <div className='circularLogo'>
                <CircularLogoSmall />
              </div>
            </div>
          </div>
          <ShareLanguage />
        </div>
        <ShareNavMobile
          openSidebar={openSidebar}
          setOpenSidebar={setOpenSidebar}
          items={items}
          activeOrder={activeOrder}
          setActiveOrder={setActiveOrder}
        />
      </header>
      <div style={{ height: "80px", width: "100%" }}></div>
    </Fragment>
  );
};

export default ShareHeader;
