import React from "react";
import "./ShareDetailContent.scss";

import ShareDetailContentDetails from "./Components/ShareDetailContentDetails/ShareDetailContentDetails";
import ShareDetailContentHeader from "./Components/ShareDetailContentHeader/ShareDetailContentHeader";
import ShareDetailContentTracking from "./Components/ShareDetailContentTracking/ShareDetailContentTracking";
import ShareDetailContentStatusUpdates from "./Components/ShareDetailContentStatusUpdates/ShareDetailContentStatusUpdates";

const ShareDetailContent = ({ order }) => {
  return (
    <div id='ShareDetailContent'>
      <ShareDetailContentHeader order={order} />
      <ShareDetailContentDetails order={order} />
      <ShareDetailContentTracking order={order} />
      <ShareDetailContentStatusUpdates order={order} />
    </div>
  );
};

export default ShareDetailContent;
