import React from "react";
import "./LoadBoardsStatusField.scss";

const LoadBoardsStatusField = ({ item }) => {
  return (
    <td id='LoadBoardsStatusField'>
      <div className='container'>
        <div
          className='status'
          style={{
            backgroundColor: item?.status?.color + "2a",
            color: item?.status?.color,
          }}
        >
          <div
            className='dot'
            style={{
              backgroundColor: item?.status?.color,
            }}
          />
          <p>{item?.status?.name}</p>
        </div>
      </div>
    </td>
  );
};

export default LoadBoardsStatusField;
