import React from "react";
import "./ShareDetailContentDetails.scss";

import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";
import { FaWeightHanging } from "react-icons/fa";
import { BsAirplane, BsTruck, BsBoxSeamFill } from "react-icons/bs";
import { RiShipLine, RiRouteFill } from "react-icons/ri";
import { MdDirectionsRailway } from "react-icons/md";
import { IoMdArrowDropdown } from "react-icons/io";
import { MdLocationPin, MdLocationSearching } from "react-icons/md";

const ShareDetailContentDetails = ({ order }) => {
  const { t } = useTranslation();

  const from = order?.cargos?.[0]?.locations?.find((A) => A.direction === "A")?.country ?? "";
  const to = order?.cargos?.[0]?.locations?.find((B) => B.direction === "B")?.country ?? "";

  const renderTooltip = (
    <div className='RouteFieldContent'>
      <div>
        {order?.cargos?.[0]?.locations
          ?.filter((A) => A.direction === "A")
          ?.map((item, index) => (
            <div key={item?.id || index} className='routeContainer'>
              <div className='icon' style={{ color: "#b95000" }}>
                <MdLocationSearching />
              </div>
              <p>{item?.formatted_address}</p>
            </div>
          ))}
      </div>
      <div>
        {order?.cargos?.[0]?.locations
          ?.filter((B) => B.direction === "B")
          ?.map((item, index) => (
            <div key={item?.id || index} className='routeContainer'>
              <div className='icon' style={{ color: "#287d3c" }}>
                <MdLocationPin />
              </div>
              <p>{item?.formatted_address}</p>
            </div>
          ))}
      </div>
    </div>
  );

  return (
    <div id='ShareDetailContentDetails'>
      <div className='ShareDetailContentDetailstitle'>
        <h3>{t("share.Order details")}</h3>
      </div>

      <div className='ShareDetailContentDetailscards'>
        <div className='ShareDetailContentDetailsitem'>
          <div className='ShareDetailContentDetailsitemicon'>
            <RiRouteFill />
          </div>
          <div className='ShareDetailContentDetailsitemtext'>
            <Tooltip
              placement='bottom'
              title={renderTooltip}
              classes={{ tooltip: "ShareDetailContentDetailsRouteFieldTooltip" }}
            >
              <h5 className='locations'>
                {from} - {to}{" "}
                <span>
                  <IoMdArrowDropdown />
                </span>
              </h5>
            </Tooltip>
          </div>
        </div>
        <div className='ShareDetailContentDetailscardsLine'></div>
        <div className='ShareDetailContentDetailsitem'>
          <div className='ShareDetailContentDetailsitemicon'>
            {order?.type_group?.id === 1 && <BsTruck />}
            {order?.type_group?.id === 2 && <RiShipLine />}
            {order?.type_group?.id === 3 && <BsAirplane />}
            {order?.type_group?.id === 4 && <MdDirectionsRailway />}
          </div>
          <div className='ShareDetailContentDetailsitemtext'>
            <h5>{order?.type?.name || "-"}</h5>
          </div>
        </div>
        <div className='ShareDetailContentDetailscardsLine'></div>
        <div className='ShareDetailContentDetailsitem'>
          <div className='ShareDetailContentDetailsitemicon'>
            <BsBoxSeamFill />
          </div>
          <div className='ShareDetailContentDetailsitemtext'>
            <h5>{order?.cargos?.[0]?.type?.name || "-"}</h5>
          </div>
        </div>
        <div className='ShareDetailContentDetailscardsLine'></div>
        <div className='ShareDetailContentDetailsitem'>
          <div className='ShareDetailContentDetailsitemicon'>
            <FaWeightHanging />
          </div>
          <div className='ShareDetailContentDetailsitemtext'>
            {order?.weight ? <h5>{order?.weight || "-"} {t("share.KG")}</h5> : <h5>-</h5>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareDetailContentDetails;
