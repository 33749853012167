import React, { useEffect, useState } from "react";
import "./PreferencesEditModal.scss";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { MdOutlineSettingsSuggest, MdPinDrop, MdLocalShipping, MdNearMe } from "react-icons/md";

import DModal from "../../../componentsDashboard/Modal/DModal/DModal";
import DSelect from "../../../componentsDashboard/Form/DSelect/DSelect";
import SelectableButton from "../../../componentsDashboard/CustomersPartners/Components/SelectableButton/SelectableButton";
import DErrorText from "../../../componentsDashboard/Form/DErrorText/DErrorText";
import { RiArrowLeftRightFill } from "react-icons/ri";
import {
  resetUpdatePreferences,
  updatePreferences,
} from "../../../store/subscribers/subscribersSlice";

const PreferencesEditModal = ({ isOpen, isClose, token }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    getPreferencesResult,
    getProfileResult,
    updatePreferencesLoading,
    updatePreferencesResult,
    updatePreferencesError,
  } = useSelector((state) => state.subscribers);
  const { getAllCountriesSelect } = useSelector((state) => state.settings);
  const { getTrailerTypesRoadResult } = useSelector((state) => state.trailerType);
  const { getAllRoutesRoadListResult } = useSelector((state) => state.routes);

  const [country, setCountry] = useState(null);
  const [selectedTrailerTypes, setSelectedTrailerTypes] = useState(
    getPreferencesResult?.trailer_types?.map((item) => item?.id) || []
  );
  const [selectedRoutes, setSelectedRoutes] = useState(
    getPreferencesResult?.routes?.map((item) => item?.id) || []
  );

  const submitHandler = () => {
    const data = {
      signature: token,
      data: {
        registration_country_id: country?.value?.id,
        trailer_types: selectedTrailerTypes,
        routes: selectedRoutes,
      },
    };

    dispatch(updatePreferences(data));
  };

  useEffect(() => {
    if (updatePreferencesResult) {
      dispatch(resetUpdatePreferences());
      isClose();
    }
  }, [dispatch, isClose, updatePreferencesResult]);

  useEffect(() => {
    const selectedCountry = getAllCountriesSelect?.find(
      (item) => item?.value?.id === getProfileResult?.registration_country?.id
    );
    setCountry(selectedCountry);
  }, [getAllCountriesSelect, getProfileResult]);

  return (
    <DModal
      isOpen={isOpen}
      isClose={isClose}
      title={t("tms.modals.titles.Select preferences")}
      submitText={t("tms.modals.buttons.Set preferences")}
      icon={<MdOutlineSettingsSuggest />}
      loading={updatePreferencesLoading}
      onClick={submitHandler}
      status='info'
      btnBackground={"linear-gradient(300deg, #5e2bff 18.14%, #5e2bff 69.4%)"}
    >
      <div id='PreferencesEditModal'>
        <div
          className='cover'
          style={{ backgroundImage: `url(/assets/images/dashboard/preferencesCover.svg)` }}
        />
        <div className='formItem'>
          <div className='label'>
            <div className='icon'>
              <MdPinDrop />
            </div>
            <div className='title'>{t("tms.modals.labels.Home region")}</div>
          </div>
          <div className='select'>
            <DSelect
              search={true}
              options={getAllCountriesSelect}
              value={country}
              setValue={setCountry}
            />
          </div>
        </div>
        <div className='formItem'>
          <div className='label'>
            <div className='icon'>
              <MdLocalShipping />
            </div>
            <div className='title'>{t("tms.modals.labels.Trailer types")}</div>
          </div>
          <div className='trailerTypesList'>
            {getTrailerTypesRoadResult &&
              getTrailerTypesRoadResult?.map((item) =>
                item?.types?.map((trailerType) => (
                  <SelectableButton
                    key={trailerType?.id}
                    item={trailerType}
                    selectedTypes={selectedTrailerTypes}
                    setSelectedTypes={setSelectedTrailerTypes}
                    selected={selectedTrailerTypes?.includes(trailerType?.id)}
                  />
                ))
              )}
            <DErrorText error={updatePreferencesError?.errors?.trailer_types?.[0]} />
          </div>
        </div>
        <div className='formItem'>
          <div className='label'>
            <div className='icon'>
              <MdNearMe />
            </div>
            <div className='title'>{t("tms.modals.labels.Preferred routes")}</div>
          </div>
          <div className='destionationHeader'>
            <div className='homeCountry'>{country?.value?.name}</div>
            <div className='icon'>
              <RiArrowLeftRightFill />
            </div>
            <p>{t("tms.modals.others.Selected regions")}</p>
          </div>
          <div className='trailerTypesList'>
            {getAllRoutesRoadListResult &&
              getAllRoutesRoadListResult.map((route) => (
                <SelectableButton
                  key={route?.id}
                  item={route}
                  selectedTypes={selectedRoutes}
                  setSelectedTypes={setSelectedRoutes}
                  selected={selectedRoutes?.includes(route?.id)}
                />
              ))}
            <DErrorText error={updatePreferencesError?.errors?.routes?.[0]} />
          </div>
        </div>
      </div>
    </DModal>
  );
};

export default PreferencesEditModal;
