import React from "react";

const BoxIcon = () => {
  return (
    <span>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
      >
        <path
          d='M11.1498 12.3354V21.5154C11.096 21.4966 11.0454 21.4696 10.9998 21.4354L4.48982 17.5254C4.19814 17.3587 3.95643 17.117 3.78982 16.8254C3.61642 16.5212 3.52994 16.1753 3.53982 15.8254V7.75535L11.1498 12.3354ZM20.4598 7.75535V15.8554C20.447 16.189 20.3544 16.5148 20.1898 16.8054C20.0334 17.1038 19.7937 17.3505 19.4998 17.5154L12.9898 21.4254L12.8498 21.4954V12.3254L16.1098 10.3254V13.0954C16.1098 13.3208 16.1994 13.537 16.3588 13.6964C16.5182 13.8558 16.7344 13.9454 16.9598 13.9454C17.1853 13.9454 17.4015 13.8558 17.5609 13.6964C17.7203 13.537 17.8098 13.3208 17.8098 13.0954V9.35535L20.4598 7.75535ZM15.2798 8.90535L11.9998 10.9054L4.33982 6.30535L4.44982 6.23535L7.50982 4.60535L15.2798 8.90535ZM19.6498 6.28535L16.9398 7.90535L9.29982 3.62535L10.9598 2.75535C11.2637 2.5791 11.6086 2.48597 11.9598 2.48535C12.3115 2.49264 12.6555 2.58897 12.9598 2.76535L19.4298 6.22535C19.5069 6.22777 19.5822 6.24833 19.6498 6.28535Z'
          fill='#848A8D'
        />
      </svg>
    </span>
  );
};

export default BoxIcon;
