import React from "react";
import "./LoadBoardsWeightField.scss";
import { useTranslation } from "react-i18next";

const LoadBoardsWeightField = ({ item }) => {
  const { t } = useTranslation();
  return (
    <td id='LoadBoardsWeightField'>
      <div className='LoadBoardsWeightFieldcontent'>
        <div className='weight'>
          {item?.weight} {t("subscribers.tableList.KG")}
        </div>
        <p>{item?.cargos?.[0]?.type?.name || ""}</p>
      </div>
    </td>
  );
};

export default LoadBoardsWeightField;
