import React from "react";
import "./ShareSidebar.scss";

import ShareSidebarHeader from "./ShareSidebarHeader/ShareSidebarHeader";
import ShareSidebarList from "./ShareSidebarList/ShareSidebarList";

const ShareSidebar = ({ openSidebar, setOpenSidebar, items, activeOrder, setActiveOrder }) => {
  return (
    <div id='ShareSidebar'>
      <ShareSidebarHeader items={items} />
      <ShareSidebarList
        openSidebar={openSidebar}
        setOpenSidebar={setOpenSidebar}
        items={items}
        activeOrder={activeOrder}
        setActiveOrder={setActiveOrder}
      />
    </div>
  );
};

export default ShareSidebar;
