import _request from "./../_request";

const getSubscribersLoadboardsOrders = (ISO, data) => {
  const {
    signature,
    page,
    pickup_next_three_days,
    pickup_address,
    dropoff_address,
    pickup_date_max,
    pickup_date_min,
    registration_country,
    subscriber_preferences,
    routes,
    trailer_types,
    sort_by,
    has_my_bids,
  } = data || {};

  let url = `/subscribers/${signature}/loadboards?`;

  url += page ? `&page=${page}` : "";
  url += pickup_next_three_days ? `&pickup_next_three_days=${pickup_next_three_days}` : "";
  url += pickup_address ? `&pickup_address=${pickup_address}` : "";
  url += dropoff_address ? `&dropoff_address=${dropoff_address}` : "";
  url += pickup_date_max ? `&pickup_date[max]=${pickup_date_max}` : "";
  url += pickup_date_min ? `&pickup_date[min]=${pickup_date_min}` : "";
  url += registration_country ? `&registration_country=${registration_country}` : "";
  url += subscriber_preferences ? `&subscriber_preferences=${subscriber_preferences}` : "";
  url += sort_by ? `&sortby=${sort_by}` : "";
  url += has_my_bids ? `&has_my_bids=${has_my_bids}` : "";

  if (routes && routes?.length) {
    routes?.forEach((i) => {
      url += `&routes[]=${i}`;
    });
  }

  if (trailer_types && trailer_types?.length) {
    trailer_types?.forEach((i) => {
      url += `&trailer_types[]=${i}`;
    });
  }

  const config = {
    url,
    ISO,
  };

  return _request(config);
};

const getSubscribersLoadboardsOrder = (ISO, data) => {
  const config = {
    url: `/subscribers/${data?.signature}/loadboards/${data?.orderId}`,
    ISO,
  };
  return _request(config);
};

const getSubscribersConfirmedOrders = (ISO, data) => {
  const {
    signature,
    page,
    pickup_address,
    dropoff_address,
    pickup_date_max,
    pickup_date_min,
    registration_country_id,
    routes,
    trailer_types,
    sort_by,
  } = data || {};

  let url = `/subscribers/${signature}/confirmed-orders?`;

  url += page ? `&page=${page}` : "";
  url += pickup_address ? `&pickup_address=${pickup_address}` : "";
  url += dropoff_address ? `&dropoff_address=${dropoff_address}` : "";
  url += pickup_date_max ? `&pickup_date[max]=${pickup_date_max}` : "";
  url += pickup_date_min ? `&pickup_date[min]=${pickup_date_min}` : "";
  url += registration_country_id ? `&registration_country_id=${registration_country_id}` : "";
  url += sort_by ? `&sortby=${sort_by}` : "";

  if (routes && routes?.length) {
    routes?.forEach((i) => {
      url += `&routes[]=${i}`;
    });
  }

  if (trailer_types && trailer_types?.length) {
    trailer_types?.forEach((i) => {
      url += `&trailer_types[]=${i}`;
    });
  }

  const config = {
    url,
    ISO,
  };
  return _request(config);
};

const getProfile = (ISO, signature) => {
  const config = {
    url: `/subscribers/${signature}`,
    ISO,
  };
  return _request(config);
};

const getPreferences = (ISO, signature) => {
  const config = {
    url: `/subscribers/${signature}/preferences`,
    ISO,
  };
  return _request(config);
};

const updatePreferences = async (ISO, signature, data) => {
  const config = {
    url: `/subscribers/${signature}/preferences`,
    method: "put",
    ISO,
  };
  return _request(config, data);
};

const getTrailerTypes = (ISO) => {
  const config = {
    url: "/subscribers/trailer-types",
    ISO,
  };
  return _request(config);
};

const getRoutes = (ISO) => {
  const config = {
    url: "/subscribers/routes",
    ISO,
  };
  return _request(config);
};

const makeBid = (ISO, orderId, signature, data) => {
  const config = {
    url: `/subscribers/${signature}/make-bid/${orderId}`,
    method: "post",
    ISO,
  };
  return _request(config, data);
};

const updateBid = (ISO, bidId, signature, data) => {
  const config = {
    url: `/subscribers/${signature}/update-bid/${bidId}`,
    method: "put",
    ISO,
  };
  return _request(config, data);
};

const sendOtp = (ISO, signature, data) => {
  const config = {
    url: `/subscribers/${signature}/send-otp`,
    method: "post",
    ISO,
  };
  return _request(config, data);
};
const setNotificationTime = (ISO, signature, data) => {
  const config = {
    url: `/subscribers/${signature}/set-notification-time`,
    method: "post",
    ISO,
  };
  return _request(config, data);
};
const verify = (ISO, signature, data) => {
  const config = {
    url: `/subscribers/${signature}/verify`,
    method: "post",
    ISO,
  };
  return _request(config, data);
};
const unsubscribe = (ISO, signature, data) => {
  const config = {
    url: `/subscribers/${signature}/unsubscribe`,
    method: "put",
    ISO,
  };
  return _request(config, data);
};
const resubscribe = (ISO, signature) => {
  const config = {
    url: `/subscribers/${signature}/resubscribe`,
    method: "put",
    ISO,
  };
  return _request(config);
};

const getCurrencies = async (ISO, BranchId) => {
  const config = {
    url: `/currency/${BranchId}`,
    ISO,
  };
  return _request(config);
};

const getPaymentType = async (ISO) => {
  const config = {
    url: `/payment-type`,
    ISO,
  };
  return _request(config);
};

const updateSubscriber = async (ISO, signature, data) => {
  const config = {
    url: `/subscribers/${signature}`,
    method: "put",
    ISO,
  };
  return _request(config, data);
};

const subscribersService = {
  getSubscribersLoadboardsOrders,
  getSubscribersLoadboardsOrder,
  getSubscribersConfirmedOrders,
  getPreferences,
  updatePreferences,
  getTrailerTypes,
  getRoutes,
  makeBid,
  sendOtp,
  setNotificationTime,
  verify,
  unsubscribe,
  resubscribe,
  getCurrencies,
  getPaymentType,
  getProfile,
  updateSubscriber,
  updateBid,
};

export default subscribersService;
