import React from "react";
import SubscribeTitleComponent from "../SubscribeTitleComponent/SubscribeTitleComponent";
import { IoLocationSharp } from "react-icons/io5";
import "./SubscribeRouteComponent.scss";
import { FaTruck } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import AddressDetails2 from "../../../componentsDashboard/Orders/AddressDetails/AddressDetails2/AddressDetails2";

const SubscribeRouteComponent = ({ order }) => {
  const { t } = useTranslation();

  return (
    <div id='SubscribeRouteComponent'>
      <div className='SubscribeGeneralCard'>
        <div className='CardHeader'>
          <SubscribeTitleComponent
            title={t("subscribers.details.Route")}
            icon={<IoLocationSharp />}
          />
          {order?.type?.name && (
            <div className='ftl'>
              <div className='icon'>
                <FaTruck />
              </div>
              <h4>{order?.type?.name}</h4>
            </div>
          )}
        </div>
        <div className='CardBody hand-p'>{order && <AddressDetails2 getOrderResult={order} />}</div>
      </div>
    </div>
  );
};

export default SubscribeRouteComponent;
