import React from "react";
import "./ShareDetailContentStatusUpdatesItem.scss";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";
import "moment/locale/ka";
import "moment/locale/en-gb";
import "moment/locale/ru";
import "moment/locale/uz";
import { RiCalendarEventFill } from "react-icons/ri";

import { useFromNowDate } from "./../../../../../../helper/useFromNowDate";

const ShareDetailContentStatusUpdatesItem = ({ item, index }) => {
  const { t } = useTranslation();

  const { ISO } = useSelector((state) => state.lang);
  moment.locale(ISO);

  const estDate = moment(item?.est).format("DD MMMM");
  const etaDate = moment(item?.eta).format("DD MMMM");
  const etdDate = moment(item?.etd).format("DD MMMM");
  const status = item?.status?.name || "-";
  const lastLocation = item?.location?.formatted_address || "-";
  const updateDate = useFromNowDate(item?.created_at);

  return (
    <div id='ShareDetailContentStatusUpdatesItem'>
      <div className={index === 0 ? "icon active" : "icon"}>
        <RiCalendarEventFill />
        <div className='testarrow'></div>
      </div>
      <div className='content'>
        <div className='top'>
          {item?.status?.name && <h4 className='statusText'>{status}</h4>}
          {item?.location?.formatted_address && (
            <h5 className='textItem'>
              {t("share.Current location")}: <span>{lastLocation}</span>
            </h5>
          )}
          {item?.est && (
            <h5 className='textItem'>
              {t("share.EST")}: <span>{estDate}</span>
            </h5>
          )}
          {item?.etd && (
            <h5 className='textItem'>
              {t("share.ETD")}: <span>{etdDate}</span>
            </h5>
          )}
          {item?.eta && (
            <h5 className='textItem'>
              {t("share.ETA")}: <span>{etaDate}</span>
            </h5>
          )}
        </div>
        {item?.created_at && <p className='time'>{updateDate}</p>}
      </div>
    </div>
  );
};

export default ShareDetailContentStatusUpdatesItem;
