import React from "react";
import "./LoadBoardsOrderField.scss";

import { Link, useParams } from "react-router-dom";

const LoadBoardsOrderField = ({ item }) => {
  const { token } = useParams();

  return (
    <td id='LoadBoardsOrderField' className='link'>
      <Link to={`/subscribers/${token}/${item?.id}`}>{item?.id}</Link>
    </td>
  );
};

export default LoadBoardsOrderField;
