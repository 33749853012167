import React, { Fragment, useState } from "react";
import "./SubscribersFilters.scss";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { FaFilter } from "react-icons/fa";

import PreferencesFilter from "../../componentsDashboard/LoadBoards/PreferencesFilter/PreferencesFilter";
import FilterPlainToggleButton from "./Components/FilterPlainToggleButton/FilterPlainToggleButton";
import DTextButton from "../../componentsDashboard/Form/DButtons/DTextButton/DTextButton";
import FiltersCheckboxMultiselect from "../../componentsDashboard/FilterForms/FiltersCheckboxMultiselect/FiltersCheckboxMultiselect";
import FilterDetailSearchModal from "./Components/FilterDetailSearchModal/FilterDetailSearchModal";
import SubscribersFiltersMobile from "./SubscribersFiltersMobile/SubscribersFiltersMobile";

const SubscribersFilters = ({
  preferences,
  setPreferences,
  pickupDropoffInGeorgia,
  setPickupDropoffInGeorgia,
  onClear,
  pickupNext3Days,
  setPickupNext3Days,
  selectedRoutes,
  setSelectedRoutes,
  routesOptions,
  routesSearchParamName,
  selectedTrailers,
  setSelectedTrailers,
  trailersOptions,
  trailerSearchParamName,
  pickupCountry,
  setPickupCountry,
  dropoffCountry,
  setDropoffCountry,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  handlePreferenceFilterSelect,
  handlePreferenceFilterDeselect,
  sort,
  setSort,
}) => {
  const { t } = useTranslation();
  const [openDetailSearchModal, setOpenDetailSearchModal] = useState(false);

  const { getPreferencesResult, getProfileResult } = useSelector((state) => state.subscribers);

  return (
    <Fragment>
      <div id='SubscribersFilters'>
        <div className='filters'>
          {setPreferences && (
            <PreferencesFilter
              preferences={preferences}
              setPreferences={setPreferences}
              withoutEdit
              handleClick={
                preferences ? handlePreferenceFilterDeselect : handlePreferenceFilterSelect
              }
              orderCount={getPreferencesResult?.order_count}
            />
          )}
          {setSelectedRoutes && (
            <FiltersCheckboxMultiselect
              selected={selectedRoutes}
              setSelected={setSelectedRoutes}
              options={routesOptions}
              placeholder={t("subscribers.board.Routes")}
              searchParamName={routesSearchParamName}
            />
          )}
          {setSelectedTrailers && (
            <FiltersCheckboxMultiselect
              selected={selectedTrailers}
              setSelected={setSelectedTrailers}
              options={trailersOptions}
              placeholder={t("subscribers.board.Trailer type")}
              searchParamName={trailerSearchParamName}
            />
          )}
          {setPickupDropoffInGeorgia && (
            <FilterPlainToggleButton
              text={
                t("subscribers.board.Pickup/Drop off in ") +
                getProfileResult?.registration_country?.name
              }
              isActive={pickupDropoffInGeorgia}
              setIsActive={setPickupDropoffInGeorgia}
              searchParamName='pickupDropoffInGeorgia'
            />
          )}
          {setPickupNext3Days && (
            <FilterPlainToggleButton
              text={t("subscribers.board.Pick up next 3 days")}
              isActive={pickupNext3Days}
              setIsActive={setPickupNext3Days}
              searchParamName='pickupNext3Days'
            />
          )}
          <div className='detailSearchButton' onClick={() => setOpenDetailSearchModal(true)}>
            <div className='icon'>
              <FaFilter />
            </div>
            <p>{t("subscribers.board.Detail search")}</p>
          </div>
        </div>
        <div className='clear'>
          <DTextButton title={t("subscribers.board.Clear all")} onClick={onClear} />
        </div>
        <SubscribersFiltersMobile
          preferences={preferences}
          setPreferences={setPreferences}
          handlePreferenceFilterSelect={handlePreferenceFilterSelect}
          handlePreferenceFilterDeselect={handlePreferenceFilterDeselect}
          sort={sort}
          setSort={setSort}
          pickupDropoffInGeorgia={pickupDropoffInGeorgia}
          setPickupDropoffInGeorgia={setPickupDropoffInGeorgia}
          pickupNext3Days={pickupNext3Days}
          setPickupNext3Days={setPickupNext3Days}
          selectedRoutes={selectedRoutes}
          setSelectedRoutes={setSelectedRoutes}
          routesOptions={routesOptions}
          selectedTrailers={selectedTrailers}
          setSelectedTrailers={setSelectedTrailers}
          trailersOptions={trailersOptions}
          pickupCountry={pickupCountry}
          setPickupCountry={setPickupCountry}
          dropoffCountry={dropoffCountry}
          setDropoffCountry={setDropoffCountry}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          onClear={onClear}
        />
      </div>
      {openDetailSearchModal && (
        <FilterDetailSearchModal
          isClose={() => setOpenDetailSearchModal(false)}
          isOpen={openDetailSearchModal}
          onClear={onClear}
          pickupCountry={pickupCountry}
          setPickupCountry={setPickupCountry}
          dropoffCountry={dropoffCountry}
          setDropoffCountry={setDropoffCountry}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
      )}
    </Fragment>
  );
};

export default SubscribersFilters;
