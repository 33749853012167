import React, { Fragment } from "react";
import "./ShareSidebarListItem.scss";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";
import "moment/locale/ka";
import "moment/locale/en-gb";
import "moment/locale/ru";
import "moment/locale/uz";
import { BsTruck } from "react-icons/bs";
import { BsAirplane } from "react-icons/bs";
import { RiShipLine } from "react-icons/ri";
import { MdDirectionsRailway } from "react-icons/md";
import { RiCheckboxCircleLine, RiTimeLine, RiContrast2Line } from "react-icons/ri";
import { Tooltip } from "@mui/material";

const ShareSidebarListItem = ({
  order,
  index,
  openSidebar,
  setOpenSidebar,
  activeOrder,
  setActiveOrder,
}) => {
  const { t } = useTranslation();

  const { ISO } = useSelector((state) => state.lang);
  moment.locale(ISO);

  const pickUpDate = moment(order?.cargos?.[0]?.pickup_date).format("DD MMMM");
  const dropoffDate = moment(order?.cargos?.[0]?.dropoff_date).format("DD MMMM");
  const estDate = moment(order?.last_shipment_status_update?.est).format("DD MMMM");
  const etaDate = moment(order?.last_shipment_status_update?.eta).format("DD MMMM");
  const etdDate = moment(order?.last_shipment_status_update?.etd).format("DD MMMM");
  const lastLocation = `${order?.last_shipment_status_update?.location?.city || "-"}, ${
    order?.last_shipment_status_update?.location?.country || "-"
  }`;

  const transports = order?.cargos?.filter((cargo) => cargo?.transport);
  const transportsCount = transports?.length || 0;

  const renderShareSidebarListItemTransportsTooltip = (
    <div className='list'>
      {transports?.map((tr, i) => (
        <p key={i}>{tr?.transport?.number || "-"}</p>
      ))}
    </div>
  );

  return (
    <li
      id='ShareSidebarListItem'
      className={order.id === activeOrder.id ? "active" : ""}
      onClick={() => {
        setActiveOrder(order);
        setOpenSidebar(false);
      }}
    >
      <div className='itemHeader'>
        <div className='trailerType'>
          {order?.type_group?.id === 1 && <BsTruck />}
          {order?.type_group?.id === 2 && <RiShipLine />}
          {order?.type_group?.id === 3 && <BsAirplane />}
          {order?.type_group?.id === 4 && <MdDirectionsRailway />}
          <h4>#{index + 1}</h4>
          {order?.type_group?.id === 1 && <h5>{order?.cargos?.[0]?.trailerType?.name || "-"}</h5>}
          {order?.type_group?.id === 2 && <h5>{t("share.Ocean")}</h5>}
          {order?.type_group?.id === 3 && <h5>{t("share.Air")}</h5>}
          {order?.type_group?.id === 4 && <h5>{t("share.Rail")}</h5>}
        </div>
        {transportsCount > 0 && (
          <div className='transports'>
            <h4>
              {transports?.[0]?.transport?.number || "-"}{" "}
              {transportsCount > 1 && (
                <Tooltip
                  placement='bottom'
                  title={renderShareSidebarListItemTransportsTooltip}
                  classes={{ tooltip: "ShareSidebarListItemTransports" }}
                >
                  <span> +{transportsCount - 1}</span>
                </Tooltip>
              )}
            </h4>
          </div>
        )}
      </div>

      {order?.status?.id === 3 && (
        <div className='itemBody confrim'>
          <div className='status'>
            <h4>{t("share.Status")}</h4>
            <RiTimeLine />
            <h5>{t("share.Waiting for pick-up")}</h5>
          </div>
          <h6 className='text'>
            {t("share.Pick up")} <span>{pickUpDate}</span>
          </h6>
        </div>
      )}

      {order?.status?.id === 4 && (
        <div className='itemBody transit'>
          {order?.last_shipment_status_update ? (
            <Fragment>
              {order?.last_shipment_status_update?.status?.name && (
                <div className='status'>
                  <h4>{t("share.Status")}</h4>
                  <RiContrast2Line />
                  <h5>{order?.last_shipment_status_update?.status?.name || "-"}</h5>
                </div>
              )}
              {order?.last_shipment_status_update?.location && (
                <h6 className='text'>
                  {t("share.Current location")} <span>{lastLocation}</span>
                </h6>
              )}
              {order?.last_shipment_status_update?.est && (
                <h6 className='text'>
                  {t("share.EST")} <span>{estDate}</span>
                </h6>
              )}
              {order?.last_shipment_status_update?.eta && (
                <h6 className='text'>
                  {t("share.ETA")} <span>{etaDate}</span>
                </h6>
              )}
              {order?.last_shipment_status_update?.etd && (
                <h6 className='text'>
                  {t("share.ETD")} <span>{etdDate}</span>
                </h6>
              )}
            </Fragment>
          ) : (
            <h6 className='nostatusupdate'>{t("share.Tracking unavailable at the moment")}</h6>
          )}
        </div>
      )}

      {(order?.status?.id === 5 || order?.status?.id === 6) && (
        <div className='itemBody complete'>
          <div className='status'>
            <h4>{t("share.Status")}</h4>
            <RiCheckboxCircleLine />
            <h5>{t("share.Delivered")}</h5>
          </div>
          <h6 className='text'>
            {t("share.Delivery date")} <span>{dropoffDate}</span>
          </h6>
        </div>
      )}
    </li>
  );
};

export default ShareSidebarListItem;
