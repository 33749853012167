import React, { useEffect } from "react";
import "./SubscribersOrderListMobile.scss";

import InfiniteLoading from "../../../componentsDashboard/ShipperAcceptedOrders/Components/UI/InfiniteLoading/InfiniteLoading";
import SubscribersOrderListMobileItem from "./SubscribersOrderListMobileItem/SubscribersOrderListMobileItem";
import NoData from "../../../componentsDashboard/ShipperAcceptedOrders/Components/UI/NoData/NoData";
import Pagination from "../../../componentsDashboard/Form/Pagination/Pagination";

const SubscribersOrderListMobile = ({
  items,
  loading,
  page,
  setPage,
  lastPage,
  currentPage,
  total,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);
  return (
    <div id='SubscribersOrderListMobile'>
      {loading && <InfiniteLoading />}
      {total === 0 ? (
        <NoData />
      ) : (
        items && (
          <div className='SubscribersOrderListMobileItems'>
            {items?.map((item) => (
              <SubscribersOrderListMobileItem item={item} key={item?.id} />
            ))}
          </div>
        )
      )}
      {lastPage && <Pagination value={page} setValue={setPage} pageCount={lastPage} />}
    </div>
  );
};

export default SubscribersOrderListMobile;
