import React, { useEffect, useState } from "react";
import "./EditProfileModal.scss";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { FaPen } from "react-icons/fa6";

import {
  resetUpdateSubscriber,
  updateSubscriber,
} from "../../../../store/subscribers/subscribersSlice";

import DModal from "../../../../componentsDashboard/Modal/DModal/DModal";
import DInputs from "../../../../componentsDashboard/Form/DInputs/DInputs";
import DSelect from "../../../../componentsDashboard/Form/DSelect/DSelect";

const EditProfileModal = ({ isOpen, isClose, token }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    updateSubscriberLoading,
    updateSubscriberResult,
    updateSubscriberError,
    getProfileResult,
  } = useSelector((state) => state.subscribers);
  const { getAllCountriesResult } = useSelector((state) => state.settings);

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState(null);

  const options =
    getAllCountriesResult &&
    getAllCountriesResult.map((item) => ({
      value: item,
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              width: "15px",
              height: "15px",
              backgroundImage: `url(${item?.flag})`,
              backgroundPosition: "cover",
              backgroundRepeat: "no-repeat",
              marginRight: "5px",
            }}
          ></div>

          <p>{item?.name}</p>
        </div>
      ),
    }));

  const filterOptionCountry = (option, inputValue) => {
    if (option) {
      const { value } = option;
      return (
        value?.name?.toLowerCase()?.includes(inputValue?.toLowerCase()) ||
        value?.code?.toLowerCase()?.includes(inputValue?.toLowerCase())
      );
    }
  };

  const handleSubmit = () => {
    dispatch(
      updateSubscriber({
        signature: token,
        data: {
          fullname: fullName,
          email: email,
          registration_country_id: country?.value?.id,
        },
      })
    );
  };

  useEffect(() => {
    if (updateSubscriberResult) {
      dispatch(resetUpdateSubscriber());
      isClose();
    }
  }, [dispatch, isClose, updateSubscriberResult]);

  useEffect(() => {
    if (getProfileResult) {
      setFullName(getProfileResult?.fullname);
      setEmail(getProfileResult?.email);
      setCountry({
        value: getProfileResult?.registration_country,
        label: (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: "15px",
                height: "15px",
                backgroundImage: `url(${getProfileResult?.registration_country?.flag})`,
                backgroundPosition: "cover",
                backgroundRepeat: "no-repeat",
                marginRight: "5px",
              }}
            />
            <p>{getProfileResult?.registration_country?.name}</p>
          </div>
        ),
      });
    }
  }, [getProfileResult]);

  return (
    <DModal
      isOpen={isOpen}
      isClose={isClose}
      title={t("subscribers.profile.Edit information")}
      icon={<FaPen />}
      submitText={t("subscribers.profile.Change information")}
      onClick={handleSubmit}
      loading={updateSubscriberLoading}
    >
      <div id='EditProfileModal'>
        <DSelect
          id='country'
          label={t("subscribers.profile.Country")}
          required
          search
          options={options}
          filterOption={filterOptionCountry}
          value={country}
          setValue={setCountry}
          error={updateSubscriberError?.errors?.registration_country_id?.[0]}
        />
        <DInputs
          type='text'
          id='fullName'
          label={t("subscribers.profile.Full name")}
          placeholder={t("subscribers.profile.Enter full name")}
          value={fullName}
          setValue={setFullName}
          required
          error={updateSubscriberError?.errors?.fullname?.[0]}
        />
        <DInputs
          type='text'
          id='email'
          label={t("subscribers.profile.Contact email")}
          placeholder={t("subscribers.profile.Enter contact email")}
          value={email}
          setValue={setEmail}
          required
          error={updateSubscriberError?.errors?.email?.[0]}
        />
      </div>
    </DModal>
  );
};

export default EditProfileModal;
