import React, { Fragment } from "react";
import "./SubscribersOrdersListItem.scss";

import RowField from "../../../componentsDashboard/Form/TableFields/RowField/RowField";
import LoadBoardsRouteField from "../../../componentsDashboard/Form/TableFields/LoadBoardFields/LoadBoardsRouteField/LoadBoardsRouteField";
import LoadBoardsPickupDateField from "../../../componentsDashboard/Form/TableFields/LoadBoardFields/LoadBoardsPickupDateField/LoadBoardsPickupDateField";
import LoadBoardsTrailerRequirementField from "../../../componentsDashboard/Form/TableFields/LoadBoardFields/LoadBoardsTrailerRequirementField/LoadBoardsTrailerRequirementField";
import LoadBoardsWeightField from "../../../componentsDashboard/Form/TableFields/LoadBoardFields/LoadBoardsWeightField/LoadBoardsWeightField";
import LoadBoardsActionField from "../../../componentsDashboard/Form/TableFields/LoadBoardFields/LoadBoardsActionField/LoadBoardsActionField";

const SubscribersOrdersListItem = ({ item }) => {
  return (
    <Fragment>
      <RowField className={"rowField"}>
        <LoadBoardsRouteField item={item} />
        <LoadBoardsPickupDateField item={item} />
        <LoadBoardsTrailerRequirementField item={item} />
        <LoadBoardsWeightField item={item} />
        <LoadBoardsActionField item={item} />
      </RowField>
    </Fragment>
  );
};

export default SubscribersOrdersListItem;
