import React from "react";
import "./ShareSidebarList.scss";

import ShareSidebarListItem from "./ShareSidebarListItem/ShareSidebarListItem";

const ShareSidebarList = ({ openSidebar, setOpenSidebar, items, activeOrder, setActiveOrder }) => {
  return (
    <div id='ShareSidebarList'>
      <ul className='orderList'>
        {items.map((order, index) => (
          <ShareSidebarListItem
            key={index}
            order={order}
            index={index}
            ///
            openSidebar={openSidebar}
            setOpenSidebar={setOpenSidebar}
            activeOrder={activeOrder}
            setActiveOrder={setActiveOrder}
          />
        ))}
      </ul>
    </div>
  );
};

export default ShareSidebarList;
