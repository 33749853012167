import React, { Fragment, useState } from "react";
import "./SubscriberPersonalInfo.scss";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { MdOutlineEmail } from "react-icons/md";
import { FaMobileScreenButton } from "react-icons/fa6";
import { IoEarthOutline } from "react-icons/io5";
import { FaPen } from "react-icons/fa";

import DButton from "../../../componentsDashboard/Form/DButtons/DButton/DButton";
import DTextButton from "../../../componentsDashboard/Form/DButtons/DTextButton/DTextButton";
import PreferencesEditModal from "../../Components/PreferencesEditModal/PreferencesEditModal";
import DIconButton from "../../../componentsDashboard/Form/DButtons/DIconButton/DIconButton";
import EditPhoneModal from "../Modals/EditPhoneModal/EditPhoneModal";
import EditProfileModal from "../Modals/EditProfileModal/EditProfileModal";

const SubscriberPersonalInfo = () => {
  const { t } = useTranslation();
  const { token } = useParams();

  const { getProfileResult } = useSelector((state) => state.subscribers);

  const [openPreferencesEditModal, setOpenPreferencesEditModal] = useState(false);
  const [openEditPhoneModal, setOpenEditPhoneModal] = useState(false);
  const [openEditProfileModal, setOpenEditProfileModal] = useState(false);

  return (
    <Fragment>
      <div id='SubscriberPersonalInfo'>
        <div className='subscriberPersonalInfoCont'>
          <div className='personAvatar icon'>
            <img src='/assets/images/subscribers/avatar.png' alt='img' />
          </div>
          <div className='personInfo'>
            <div className='fullName'>
              <h2>{getProfileResult?.fullname || "-"}</h2>
            </div>
            <div className='personContactsContainer'>
              <div className='personContacts'>
                <div className='contactItem'>
                  <div className='icon'>
                    <MdOutlineEmail />
                  </div>
                  <p>{getProfileResult?.email || "-"}</p>
                </div>
                <div className='contactItem'>
                  <div className='icon'>
                    <FaMobileScreenButton />
                  </div>
                  {getProfileResult?.phone && (
                    <p>
                      +{getProfileResult?.phone_index?.phone_index} {getProfileResult?.phone}
                    </p>
                  )}
                  <DIconButton icon={<FaPen />} onClick={() => setOpenEditPhoneModal(true)} />
                </div>
                <div className='contactItem'>
                  <div className='icon'>
                    <IoEarthOutline />
                  </div>
                  <p>{getProfileResult?.registration_country?.name || "-"}</p>
                </div>
              </div>
              <div className='editButton'>
                <DTextButton
                  title={t("subscribers.profile.Edit")}
                  rightIcon={<FaPen />}
                  info
                  onClick={() => setOpenEditProfileModal(true)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='editPreferenceBtn'>
          <DButton
            leftIcon={<FaPen />}
            title={t("subscribers.profile.Edit preferences")}
            onClick={() => setOpenPreferencesEditModal(true)}
          />
        </div>
      </div>
      {openPreferencesEditModal && (
        <PreferencesEditModal
          isOpen={openPreferencesEditModal}
          isClose={() => setOpenPreferencesEditModal(false)}
          token={token}
        />
      )}
      {openEditPhoneModal && (
        <EditPhoneModal
          isOpen={openEditPhoneModal}
          isClose={() => setOpenEditPhoneModal(false)}
          token={token}
        />
      )}
      {openEditProfileModal && (
        <EditProfileModal
          isOpen={openEditProfileModal}
          isClose={() => setOpenEditProfileModal(false)}
          token={token}
        />
      )}
    </Fragment>
  );
};

export default SubscriberPersonalInfo;
