import React, { Fragment } from "react";
import "./SubscribersProfileOrdersListItem.scss";

import RowField from "../../../../componentsDashboard/Form/TableFields/RowField/RowField";
import LoadBoardsRouteField from "../../../../componentsDashboard/Form/TableFields/LoadBoardFields/LoadBoardsRouteField/LoadBoardsRouteField";
import LoadBoardsTrailerRequirementField from "../../../../componentsDashboard/Form/TableFields/LoadBoardFields/LoadBoardsTrailerRequirementField/LoadBoardsTrailerRequirementField";
import LoadBoardsWeightField from "../../../../componentsDashboard/Form/TableFields/LoadBoardFields/LoadBoardsWeightField/LoadBoardsWeightField";
import LoadBoardsOrderField from "../../../../componentsDashboard/Form/TableFields/LoadBoardFields/LoadBoardsOrderField/LoadBoardsOrderField";
import LoadBoardsDeliveredField from "../../../../componentsDashboard/Form/TableFields/LoadBoardFields/LoadBoardsDeliveredField/LoadBoardsDeliveredField";
import LoadBoardsStatusField from "../../../../componentsDashboard/Form/TableFields/LoadBoardFields/LoadBoardsStatusField/LoadBoardsStatusField";

const SubscribersProfileOrdersListItem = ({ item }) => {
  return (
    <Fragment>
      <RowField className={"rowField"}>
        <LoadBoardsOrderField item={item} />
        <LoadBoardsRouteField withoutLink item={item} />
        <LoadBoardsTrailerRequirementField item={item} />
        <LoadBoardsWeightField item={item} />
        <LoadBoardsDeliveredField item={item} />
        <LoadBoardsStatusField item={item} />
      </RowField>
    </Fragment>
  );
};

export default SubscribersProfileOrdersListItem;
