import React from "react";
import "./ShareSidebarHeader.scss";

import { useTranslation } from "react-i18next";
import { IoMdPin, IoIosArrowRoundDown } from "react-icons/io";
import { FaArrowRight } from "react-icons/fa";
import { GoDotFill } from "react-icons/go";
import { BsTruck } from "react-icons/bs";
import { BsAirplane } from "react-icons/bs";
import { RiShipLine } from "react-icons/ri";
import { MdDirectionsRailway } from "react-icons/md";
import { Tooltip } from "@mui/material";

const ShareSidebarHeader = ({ items }) => {
  const { t } = useTranslation();

  const locationA =
    items?.[0]?.cargos?.[0]?.locations?.filter((loc) => loc?.direction === "A") || [];
  const locationB =
    items?.[0]?.cargos?.[0]?.locations?.filter((loc) => loc?.direction === "B") || [];

  const pickUpAddress = `${locationA[0]?.city || "-"}, ${locationA[0]?.country || "-"}`;
  const pickUpAddressCount = locationA?.length - 1 || 0;
  const dropOffAddress = `${locationB[0]?.city || "-"}, ${locationB[0]?.country || "-"}`;
  const dropOffAddressCount = locationB?.length - 1 || 0;

  const totalWeight = items?.reduce((sum, item) => sum + item?.weight, 0);
  const orderType = items?.[0]?.type?.name || "-";
  const orderCount = items.length;

  const renderlocationATooltip = (
    <div className='list'>
      {locationA.map((loc, i) => (
        <p key={i}>
          {loc?.city || "-"}, {loc?.country || "-"}
        </p>
      ))}
    </div>
  );

  const renderlocationBTooltip = (
    <div className='list'>
      {locationB.map((loc, i) => (
        <p key={i}>
          {loc?.city || "-"}, {loc?.country || "-"}
        </p>
      ))}
    </div>
  );

  return (
    <div id='ShareSidebarHeader'>
      <div className='title'>
        <h2>{t("share.Shipment tracking")}</h2>
      </div>
      <div className='stats'>
        <div className='rowcontainer'>
          <div className='pin'>
            <IoMdPin />
          </div>
          <div className='address'>
            <h4>
              {pickUpAddress}
              {pickUpAddressCount && (
                <Tooltip
                  placement='bottom'
                  title={renderlocationATooltip}
                  classes={{ tooltip: "SharepickUpAddressCountTooltipA" }}
                >
                  <span> +{pickUpAddressCount}</span>
                </Tooltip>
              )}
            </h4>
            <FaArrowRight />
            <h4>
              {dropOffAddress}
              {dropOffAddressCount && (
                <Tooltip
                  placement='bottom'
                  title={renderlocationBTooltip}
                  classes={{ tooltip: "ShareTrackingTransportTooltipB" }}
                >
                  <span> +{dropOffAddressCount}</span>
                </Tooltip>
              )}
            </h4>
          </div>
        </div>
        <div className='rowcontainer'>
          <div className='arrow'>
            <IoIosArrowRoundDown />
          </div>
          <div className='desc'>
            <h5>{totalWeight}KG</h5>
            <div className='dot'>
              <GoDotFill />
            </div>
            <h6>{orderType}</h6>
            <div className='dot'>
              <GoDotFill />
            </div>
            <div className='orderCount'>
              {items?.[0]?.type_group?.id === 1 && <BsTruck />}
              {items?.[0]?.type_group?.id === 2 && <RiShipLine />}
              {items?.[0]?.type_group?.id === 3 && <BsAirplane />}
              {items?.[0]?.type_group?.id === 4 && <MdDirectionsRailway />}
              <h4>{orderCount}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareSidebarHeader;
