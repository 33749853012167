import React, { Fragment, useState } from "react";
import "./ShareDetailContentStatusUpdates.scss";

import { FaRegMap } from "react-icons/fa";
import { useTranslation } from "react-i18next";

import ShareDetailContentStatusUpdatesItem from "./ShareDetailContentStatusUpdatesItem/ShareDetailContentStatusUpdatesItem";
import ShareDetailContentStatusUpdatesNoTracking from "./ShareDetailContentStatusUpdatesNoTracking/ShareDetailContentStatusUpdatesNoTracking";
import DirectionShipperMapModal from "./../../../../../componentsDashboard/Orders/ShipperTracking/TrackingShipperMapModal/DirectionShipperMapModal";
import TrackingContainerModal from "../../../../../componentsDashboard/Orders/ShipperTracking/TrackingContainerModal/TrackingContainerModal";

const ShareDetailContentStatusUpdates = ({ order }) => {
  const { t } = useTranslation();

  const [openMap, setOpenMap] = useState(false);
  const [openTrackingModal, setOpenTrackingModal] = useState(false);

  const isSubscribed = order?.transports?.find((i) => i?.subscribed);

  return (
    <div id='ShareDetailContentStatusUpdates'>
      {order?.shipment_status_updates && order?.shipment_status_updates?.length > 0 ? (
        <Fragment>
          <div className='ShareDetailContentStatusUpdatesHeader'>
            <p className='title'>{t("share.Shipment status history")}</p>

            {order?.type_group?.id === 1 && (
              <div className='btn road' onClick={() => setOpenMap(true)}>
                <p className='btnTitle'>{t("share.View on map")}</p>
                <FaRegMap />
              </div>
            )}
            {(order?.type_group?.id === 2 || order?.type_group?.id === 3) && isSubscribed && (
              <div className='btn ocean' onClick={() => setOpenTrackingModal(true)}>
                <p className='btnTitle'>{t("share.View full tracking")}</p>
                <FaRegMap />
              </div>
            )}
          </div>
          <div className='ShareDetailContentStatusUpdatesList'>
            <div className='ShareDetailContentStatusUpdatesListWrapper'>
              {order?.shipment_status_updates.map((item, index) => (
                <ShareDetailContentStatusUpdatesItem key={index} item={item} index={index} />
              ))}
            </div>
          </div>
          {openMap && (
            <DirectionShipperMapModal
              order={order}
              isOpen={openMap}
              isClose={() => setOpenMap(false)}
            />
          )}
          {openTrackingModal && (
            <TrackingContainerModal
              order={order}
              isOpen={openTrackingModal}
              isClose={() => setOpenTrackingModal(false)}
            />
          )}
        </Fragment>
      ) : (
        <ShareDetailContentStatusUpdatesNoTracking />
      )}
    </div>
  );
};

export default ShareDetailContentStatusUpdates;
