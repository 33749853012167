import React from "react";
import "./ShareDetailFooter.scss";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const ShareDetailFooter = () => {
  const { t } = useTranslation();

  return (
    <div id='ShareDetailFooter'>
      <h2>{t("share.Looking for a quick way to see all your shipments?")}</h2>
      <div className='buttons'>
        <Link to='/shipper' className='learnMore'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='26'
            height='24'
            viewBox='0 0 26 24'
            fill='none'
          >
            <path
              d='M0.942871 12.0204C0.942871 11.8771 0.942871 11.7337 0.942871 11.5904C1.18956 5.13993 6.49342 0 13.0102 0C19.5269 0 24.8307 5.13993 25.0569 11.57C25.0569 11.7133 25.0569 11.8566 25.0569 11.9999C25.0569 18.6348 19.6503 23.9999 13.0102 23.9999C6.34953 24.0203 0.942871 18.6348 0.942871 12.0204ZM12.9691 3.91126C8.52864 3.91126 4.93106 7.49484 4.93106 11.9181C4.93106 16.3413 8.52864 19.9249 12.9691 19.9249C17.4095 19.9249 21.0071 16.3413 21.0071 11.9181C21.0277 7.51533 17.4095 3.91126 12.9691 3.91126Z'
              fill='#00B56C'
            />
            <path
              d='M16.9981 7.45508V15.4824C16.9981 15.6052 16.9775 15.7076 16.9364 15.8101C16.7513 16.322 16.0935 16.5882 15.5796 16.24L14.7367 15.6872L11.5708 13.5575V11.3663C11.5708 11.3663 11.5708 11.3458 11.5913 11.3458L11.612 11.3254C11.612 11.3254 11.612 11.3254 11.6325 11.3254L14.12 12.9841C14.1816 13.0251 14.2844 13.066 14.3667 13.066C14.4694 13.066 14.5928 13.0251 14.675 12.9431C14.7573 12.8612 14.8189 12.7384 14.8189 12.636V9.5643C14.8189 9.21616 14.9011 8.88857 15.0656 8.58139C15.4562 7.9261 16.1346 7.49603 16.9364 7.47557C16.9569 7.45508 16.9775 7.45508 16.9981 7.45508Z'
              fill='#00B56C'
            />
            <path
              d='M14.3876 10.2612V12.4933C14.3876 12.5343 14.3465 12.5343 14.3465 12.5343L11.8384 10.8755C11.7768 10.8346 11.674 10.7936 11.5917 10.7936C11.3451 10.7936 11.16 10.9984 11.16 11.2237V14.2953C11.16 14.6435 11.0778 14.9711 10.9134 15.2783C10.5228 15.9336 9.84437 16.3636 9.04257 16.3841C9.02205 16.3841 9.0015 16.3841 8.98096 16.3841V8.37727C8.98096 8.25438 9.0015 8.15199 9.04257 8.07011C9.22762 7.55814 9.8855 7.29194 10.3994 7.64005L10.6667 7.80391L14.3876 10.2612Z'
              fill='#00B56C'
            />
          </svg>
          <p>{t("share.Learn more")}</p>
        </Link>
        <Link to='/register' className='signup'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='25'
            height='24'
            viewBox='0 0 25 24'
            fill='none'
          >
            <path
              d='M0.442871 12.0204C0.442871 11.8771 0.442871 11.7337 0.442871 11.5904C0.689561 5.13993 5.99342 0 12.5102 0C19.0269 0 24.3307 5.13993 24.5569 11.57C24.5569 11.7133 24.5569 11.8566 24.5569 11.9999C24.5569 18.6348 19.1503 23.9999 12.5102 23.9999C5.84953 24.0203 0.442871 18.6348 0.442871 12.0204ZM12.4691 3.91126C8.02864 3.91126 4.43106 7.49484 4.43106 11.9181C4.43106 16.3413 8.02864 19.9249 12.4691 19.9249C16.9095 19.9249 20.5071 16.3413 20.5071 11.9181C20.5277 7.51533 16.9095 3.91126 12.4691 3.91126Z'
              fill='white'
            />
            <path
              d='M16.4981 7.45508V15.4824C16.4981 15.6052 16.4775 15.7076 16.4364 15.8101C16.2513 16.322 15.5935 16.5882 15.0796 16.24L14.2367 15.6872L11.0708 13.5575V11.3663C11.0708 11.3663 11.0708 11.3458 11.0913 11.3458L11.112 11.3254C11.112 11.3254 11.112 11.3254 11.1325 11.3254L13.62 12.9841C13.6816 13.0251 13.7844 13.066 13.8667 13.066C13.9694 13.066 14.0928 13.0251 14.175 12.9431C14.2573 12.8612 14.3189 12.7384 14.3189 12.636V9.5643C14.3189 9.21616 14.4011 8.88857 14.5656 8.58139C14.9562 7.9261 15.6346 7.49603 16.4364 7.47557C16.4569 7.45508 16.4775 7.45508 16.4981 7.45508Z'
              fill='white'
            />
            <path
              d='M13.8876 10.2612V12.4933C13.8876 12.5343 13.8465 12.5343 13.8465 12.5343L11.3384 10.8755C11.2768 10.8346 11.174 10.7936 11.0917 10.7936C10.8451 10.7936 10.66 10.9984 10.66 11.2237V14.2953C10.66 14.6435 10.5778 14.9711 10.4134 15.2783C10.0228 15.9336 9.34437 16.3636 8.54257 16.3841C8.52205 16.3841 8.5015 16.3841 8.48096 16.3841V8.37727C8.48096 8.25438 8.5015 8.15199 8.54257 8.07011C8.72762 7.55814 9.3855 7.29194 9.89942 7.64005L10.1667 7.80391L13.8876 10.2612Z'
              fill='white'
            />
          </svg>
          <p>{t("share.Sign up")}</p>
        </Link>
      </div>
    </div>
  );
};

export default ShareDetailFooter;
