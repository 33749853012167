import React, { useEffect } from "react";
import "./Pagination.scss";

import { FiChevronLeft, FiChevronRight, FiMoreHorizontal } from "react-icons/fi";
import ReactPaginate from "react-paginate";
import { useSearchParams, useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";

const Pagination = ({ value, setValue, pageCount }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("page") > pageCount) {
      searchParams.set("page", pageCount);
      setSearchParams(searchParams);
      setValue(pageCount);
    } else {
      searchParams.set("page", value);
      setSearchParams(searchParams);
    }
  }, [dispatch, navigate, setSearchParams, pageCount, searchParams, value, setValue]);

  const handlePageClick = (e) => {
    setValue(e.selected + 1);
  };

  if (pageCount < 2) {
    return;
  }

  return (
    <div id='PaginationComponent'>
      <ReactPaginate
        initialPage={value - 1}
        pageCount={pageCount}
        onPageChange={handlePageClick}
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
        previousLabel={<FiChevronLeft />}
        nextLabel={<FiChevronRight />}
        breakLabel={<FiMoreHorizontal />}
        containerClassName='PaginationContainer'
        nextClassName='arrow next'
        previousClassName='arrow previous'
        breakClassName='break'
        breakLinkClassName='breakLink'
        pageClassName='page'
        pageLinkClassName='pageLink'
        activeClassName='active'
        activeLinkClassName='activeLink'
        previousLinkClassName='previousLink'
        nextLinkClassName='nextLink'
        disabledClassName='disabled'
        disabledLinkClassName='disabledLink'
      />
    </div>
  );
};

export default Pagination;
