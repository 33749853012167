import React, { Fragment } from "react";

import Subscribers from "../routes/Subscribers";

const AppSubscribers = () => {
  
  return (
    <Fragment>
      <div id='S-Layout'>
        <Subscribers />
      </div>
    </Fragment>
  );
};

export default AppSubscribers;
