import React from "react";
import "./ShareRailprogressContainer.scss";

const ShareRailprogressContainer = ({ order }) => {
  const progress = order?.status?.id === 5 || order?.status?.id === 6 ? 100 : 0;

  return (
    <div id='ShareRailprogressContainer'>
      <div
        className='color'
        style={{
          width: parseFloat(progress) > 100 ? "100%" : `${parseFloat(progress)}%`,
        }}
      >
        {parseFloat(progress) < 1 ? (
          <div className='small'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='11'
              height='10'
              viewBox='0 0 11 10'
              fill='none'
            >
              <rect x='1' width='10' height='10' rx='5' fill='#00B56C' />
              <circle cx='6' cy='5' r='3' fill='white' />
            </svg>
          </div>
        ) : (
          <div className='dot'></div>
        )}

        {parseFloat(progress) <= 10 ? (
          <p className='percentage small'>{progress}%</p>
        ) : (
          <p className='percentage large'>{progress}%</p>
        )}
      </div>
    </div>
  );
};

export default ShareRailprogressContainer;
