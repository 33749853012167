import React from "react";
import "./ShareBurgerIcon.scss";

const ShareBurgerIcon = ({ value, setValue }) => {
  return (
    <div
      id='ShareBurgerIcon'
      className={value ? "change" : ""}
      onClick={() => setValue((state) => !state)}
    >
      <div className='bar1'></div>
      <div className='bar2'></div>
      <div className='bar3'></div>
    </div>
  );
};

export default ShareBurgerIcon;
