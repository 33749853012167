import React, { Fragment } from "react";
import "./ShareTrackingInfoStatus.scss";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { FaCheck } from "react-icons/fa";
import moment from "moment";
import "moment/locale/ka";
import "moment/locale/en-gb";
import "moment/locale/ru";
import "moment/locale/uz";

import { useFromNowDate } from "./../../../../../../helper/useFromNowDate";

const ShareTrackingInfoStatus = ({ order }) => {
  const { t } = useTranslation();

  const { ISO } = useSelector((state) => state.lang);
  moment.locale(ISO);

  const dropoffDate = moment(order?.cargos?.[0]?.dropoff_date).format("MMM Do");
  const updateDate = useFromNowDate(order?.last_shipment_status_update?.created_at);
  const estDate = moment(order?.last_shipment_status_update?.est).format("MMM Do");
  const etaDate = moment(order?.last_shipment_status_update?.eta).format("MMM Do");
  const etdDate = moment(order?.last_shipment_status_update?.etd).format("MMM Do");

  return (
    <Fragment>
      {order?.status?.id === 3 && (
        <div id='ShareTrackingInfoStatus' className='confrim'>
          <div className='statusCard'>
            <p>{t("share.Awaiting pick up")}</p>
          </div>
          <div>
            <h6>
              {t("share.Estimated delivery")}: <span>-</span>
            </h6>
            {order?.last_shipment_status_update?.created_at && (
              <h6>
                {t("share.Update")}:{" "}
                <span>{order?.last_shipment_status_update?.created_at ? updateDate : "-"}</span>
              </h6>
            )}
          </div>
        </div>
      )}
      {order?.status?.id === 4 && (
        <div id='ShareTrackingInfoStatus' className='transit'>
          <div className='statusCard'>
            <p>{t("share.En-route")}</p>
          </div>
          <div>
            {order?.last_shipment_status_update?.est && (
              <h6>
                {t("share.Estimated delivery")}: <span>{estDate}</span>
              </h6>
            )}
            {order?.last_shipment_status_update?.eta && (
              <h6>
                {t("share.ETA delivery")}: <span>{etaDate}</span>
              </h6>
            )}
            {order?.last_shipment_status_update?.etd && (
              <h6>
                {t("share.ETD delivery")}: <span>{etdDate}</span>
              </h6>
            )}
            {order?.last_shipment_status_update?.created_at && (
              <h6>
                {t("share.Update")}: <span>{updateDate}</span>
              </h6>
            )}
          </div>
        </div>
      )}
      {(order?.status?.id === 5 || order?.status?.id === 6) && (
        <div id='ShareTrackingInfoStatus' className='complete'>
          <div className='statusCard'>
            <FaCheck />
            <p>{t("share.Completed")}</p>
          </div>
          <div>
            {order?.cargos?.[0]?.dropoff_date && (
              <h6>
                {t("share.Delivered")}:{" "}
                <span> {order?.cargos?.[0]?.dropoff_date ? dropoffDate : "-"}</span>
              </h6>
            )}
            {order?.last_shipment_status_update?.created_at && (
              <h6>
                {t("share.Update")}:{" "}
                <span>{order?.last_shipment_status_update?.created_at ? updateDate : "-"}</span>
              </h6>
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default ShareTrackingInfoStatus;
