import React, { Fragment, useEffect } from "react";
import "./ContainerTracking.scss";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { useTrackingEvent } from "../../../../../helper/useTrackingEvent";
import { getContainerTracking } from "../../../../../store/trackerManager/trackerManagerSlice";

import { MdOutlineUpdate } from "react-icons/md";
import { FaArrowRight, FaTruck } from "react-icons/fa6";
import { FaRegCalendarAlt } from "react-icons/fa";
import { MdEditCalendar } from "react-icons/md";
import { IoLocationOutline } from "react-icons/io5";
import { FaShip } from "react-icons/fa6";

import LoadingPage from "../../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";
import Character from "../../../../UI/Character/Character";
import ContainerTrackingItem from "./ContainerTrackingItem/ContainerTrackingItem";

const ContainerTracking = ({ order, container }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { getContainerTrackingLoading, getContainerTrackingResult } = useSelector(
    (state) => state.trackerManager
  );

  const DATA = getContainerTrackingResult;
  const currentLocation = DATA?.locations
    ?.flatMap((location) => location?.events)
    ?.filter((event) => event?.current_location === true)?.[0];

  const currentLocationEventName = useTrackingEvent(currentLocation?.event_type_code);
  const lastLocation = `${currentLocationEventName} • ${currentLocation?.location_name} • ${currentLocation?.event_time}`;

  useEffect(() => {
    if (container) {
      dispatch(
        getContainerTracking({
          orderId: order?.id,
          containerId: container,
        })
      );
    }
  }, [dispatch, container, order]);

  return getContainerTrackingLoading ? (
    <LoadingPage />
  ) : (
    <div id='ContainerTracking'>
      {DATA?.locations?.length > 0 ? (
        <Fragment>
          <div className='containerInfoContainer'>
            <div className='containerInfo'>
              <div className='containerType'>{order?.cargos?.[0]?.trailerType?.name}</div>
              <div className='separator' />
              <div className='destinationsContainer'>
                <div className='destination'>
                  <Character
                    title={order?.cargos?.[0]?.locations?.[0]?.formatted_address}
                    max={20}
                  />
                </div>
                <div className='arrow'>
                  <FaArrowRight />
                </div>
                <div className='destination'>
                  <Character
                    title={
                      order?.cargos?.[0]?.locations?.[order?.cargos?.[0]?.locations?.length - 1]
                        ?.formatted_address
                    }
                    max={20}
                  />
                </div>
              </div>
            </div>
            {DATA?.locations?.length > 0 && (
              <div className='lastUpdateContainer'>
                <div className='icon'>
                  <MdOutlineUpdate />
                </div>
                {/* <p>{t("shipper.modal.Last updated hours ago", { hours: 5 })}</p> */}
                {/* Comming... translation */}
              </div>
            )}
          </div>
          <div className='trackingInfoContainer'>
            <div className='trackingInfoItem'>
              <div className='icon'>
                <FaRegCalendarAlt />
              </div>
              <div className='rightContainer'>
                <div className='title'>{t("shipper.modal.Estimate time of arrival")}</div>
                <div className='value'>{DATA?.estimated_arrival || "-"}</div>
              </div>
            </div>
            <div className='trackingInfoItem'>
              <div className='icon'>
                <MdEditCalendar />
              </div>
              <div className='rightContainer'>
                <div className='title'>{t("shipper.modal.Behind schedule")}</div>
                <div
                  className='value'
                  style={{ color: DATA?.behind_schedule < 0 ? "red" : "#0AB39C" }}
                >
                  {DATA?.behind_schedule === 0 || DATA?.behind_schedule
                    ? DATA?.behind_schedule
                    : "-"}{" "}
                  {t("shipper.units.days")}
                </div>
              </div>
            </div>
            <div className='trackingInfoItem'>
              <div className='icon'>
                <IoLocationOutline />
              </div>
              <div className='rightContainer'>
                <div className='title'>{t("shipper.modal.Last location")}</div>
                <div className='value'>{lastLocation || "-"}</div>
              </div>
            </div>
          </div>
          {order?.cargos?.[0]?.locations?.[0]?.type === "LOCALITY" && (
            <ContainerTrackingItem
              title={t("shipper.modal.Road freight")}
              icon={<FaTruck />}
              done={DATA?.locations?.length > 0 || order?.statusClient?.id === 4 ? true : false}
            />
          )}
          <ContainerTrackingItem
            title={t("shipper.modal.Ocean freight")}
            icon={<FaShip />}
            done={DATA?.completed || order?.statusClient?.id === 4}
            data={DATA?.locations}
          />
          {order?.cargos?.[0]?.locations?.[order?.cargos?.[0]?.locations?.length - 1]?.type ===
            "LOCALITY" && (
            <ContainerTrackingItem
              title={t("shipper.modal.Road freight")}
              icon={<FaTruck />}
              done={order?.statusClient?.id === 4}
            />
          )}
        </Fragment>
      ) : (
        <div className='noData'>
          {t("shipper.modal.Tracking on this container is currently unavailable")}
        </div>
      )}
    </div>
  );
};

export default ContainerTracking;
