import React from "react";
import "./SubscribersHeader.scss";

import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import DButton from "../../componentsDashboard/Form/DButtons/DButton/DButton";
import DTextButton from "../../componentsDashboard/Form/DButtons/DTextButton/DTextButton";
import Lang from "../../componentsLanding/Controls/Lang/Lang";
import Logo from "../../componentsAdditional/SVG/Cargon/Logo";

const SubscribersHeader = ({ token }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <header id='SubscribersHeader'>
      <div className='wrapper'>
        <div className='SubscribersHeaderContainer'>
          <div className='leftContainer'>
            <Link to={`/subscribers/${token}`}>
              <div className='fullLogo'>
                <Logo />
              </div>
            </Link>
            <div className='signUpSuggestionContainer'>
              <div className='group'>
                <h3>{t("subscribers.board.CRM for transporters")}</h3>
                <div className='badge'>{t("subscribers.board.Free")}</div>
              </div>
              <div className='group'>
                <DTextButton
                  title={t("subscribers.board.Learn more")}
                  onClick={() => {
                    navigate("/carrier");
                  }}
                  primary
                />
                <DButton
                  title={t("subscribers.board.Sign up now")}
                  onClick={() => {
                    navigate("/register");
                  }}
                />
              </div>
            </div>
          </div>
          <div className='rightContainer'>
            <div
              className='profileButton'
              onClick={() => navigate(`/subscribers/${token}/profile`)}
            >
              <div className='icon'>
                <img src='/assets/images/subscribers/avatar.png' alt='' />
              </div>
              <p>{t("subscribers.board.Personal page")}</p>
            </div>
            <Lang />
          </div>
        </div>
      </div>
    </header>
  );
};

export default SubscribersHeader;
