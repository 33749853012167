import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import shareService from "./shareService";

const initialState = {
  getShareOrdersLoading: true,
  getShareOrdersResponce: null,
  getShareOrdersError: null,
};

export const getShareOrders = createAsyncThunk("share/getShareOrders", async (token, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    return await shareService.getShareOrders(ISO, token);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const shareSlice = createSlice({
  name: "share",
  initialState,
  reducers: {
    resetGetShareOrders: (state) => {
      state.getShareOrdersLoading = false;
      state.getShareOrdersResponce = null;
      state.getShareOrdersError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getShareOrders.pending, (state) => {
        state.getShareOrdersLoading = true;
        state.getShareOrdersResponce = null;
        state.getShareOrdersError = null;
      })
      .addCase(getShareOrders.fulfilled, (state, action) => {
        state.getShareOrdersLoading = false;
        state.getShareOrdersResponce = action.payload;
        state.getShareOrdersError = null;
      })
      .addCase(getShareOrders.rejected, (state, action) => {
        state.getShareOrdersLoading = false;
        state.getShareOrdersResponce = null;
        state.getShareOrdersError = action.payload;
      });
  },
});

export const { resetGetShareOrders } = shareSlice.actions;
export default shareSlice.reducer;
