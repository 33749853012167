import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import subscribersService from "./subscribersService";

const initialState = {
  getSubscribersLoadboardsOrdersLoading: true,
  getSubscribersLoadboardsOrdersResult: null,
  getSubscribersLoadboardsOrdersError: null,

  getSubscribersLoadboardsOrderLoading: true,
  getSubscribersLoadboardsOrderResult: null,
  getSubscribersLoadboardsOrderError: null,

  getSubscribersConfirmedOrdersLoading: true,
  getSubscribersConfirmedOrdersResult: null,
  getSubscribersConfirmedOrdersError: null,

  sendOtpLoading: false,
  sendOtpResult: null,
  sendOtpError: null,

  sendOtpAgainLoading: false,
  sendOtpAgainResult: null,
  sendOtpAgainError: null,

  setNotificationTimeLoading: false,
  setNotificationTimeResult: null,
  setNotificationTimeError: null,

  verifyLoading: false,
  verifyResult: null,
  verifyError: null,

  unsubscribeLoading: false,
  unsubscribeResult: null,
  unsubscribeError: null,

  resubscribeLoading: false,
  resubscribeResult: null,
  resubscribeError: null,

  getProfileLoading: true,
  getProfileResult: null,
  getProfileError: null,

  getPreferencesLoading: true,
  getPreferencesResult: null,
  getPreferencesError: null,

  updatePreferencesLoading: false,
  updatePreferencesResult: null,
  updatePreferencesError: null,

  getTrailerTypesLoading: true,
  getTrailerTypesResult: null,
  getTrailerTypesError: null,

  getRoutesLoading: true,
  getRoutesResult: null,
  getRoutesError: null,

  makeBidLoading: false,
  makeBidResult: null,
  makeBidError: null,

  updateBidLoading: false,
  updateBidResult: null,
  updateBidError: null,

  getPaymentTypeLoading: true,
  getPaymentTypeResult: null,
  getPaymentTypeSelect: null,
  getPaymentTypeError: null,

  getCurrenciesLoading: false,
  getCurrenciesResult: null,
  getCurrenciesSelect: null,
  getCurrenciesError: null,

  updateSubscriberLoading: false,
  updateSubscriberResult: null,
  updateSubscriberError: null,
};

export const getSubscribersLoadboardsOrders = createAsyncThunk(
  "subscribers/getSubscribersLoadboardsOrders",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      return await subscribersService.getSubscribersLoadboardsOrders(ISO, data);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getSubscribersLoadboardsOrder = createAsyncThunk(
  "subscribers/getSubscribersLoadboardsOrder",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      return await subscribersService.getSubscribersLoadboardsOrder(ISO, data);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getSubscribersConfirmedOrders = createAsyncThunk(
  "subscribers/getSubscribersConfirmedOrders",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      return await subscribersService.getSubscribersConfirmedOrders(ISO, data);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const sendOtp = createAsyncThunk("subscribers/sendOtp", async (data, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    return await subscribersService.sendOtp(ISO, data.signature, data.data);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const sendOtpAgain = createAsyncThunk("subscribers/sendOtpAgain", async (data, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    return await subscribersService.sendOtp(ISO, data.signature, data.data);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const setNotificationTime = createAsyncThunk(
  "subscribers/setNotificationTime",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      return await subscribersService.setNotificationTime(ISO, data.signature, data.data);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const verify = createAsyncThunk("subscribers/verify", async (data, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    return await subscribersService.verify(ISO, data.signature, data.data);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});
export const unsubscribe = createAsyncThunk(
  "subscribers/unsubscribe",
  async (signature, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      return await subscribersService.unsubscribe(ISO, signature);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const resubscribe = createAsyncThunk(
  "subscribers/resubscribe",
  async (signature, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      return await subscribersService.resubscribe(ISO, signature);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getProfile = createAsyncThunk(
  "subscribers/getProfile",
  async (signature, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      return await subscribersService.getProfile(ISO, signature);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getPreferences = createAsyncThunk(
  "subscribers/getPreferences",
  async (signature, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      return await subscribersService.getPreferences(ISO, signature);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updatePreferences = createAsyncThunk(
  "subscribers/updatePreferences",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      return await subscribersService.updatePreferences(ISO, data.signature, data.data);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getTrailerTypes = createAsyncThunk(
  "subscribers/getTrailerTypes",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      return await subscribersService.getTrailerTypes(ISO);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getRoutes = createAsyncThunk("subscribers/getRoutes", async (_, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    return await subscribersService.getRoutes(ISO);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const makeBid = createAsyncThunk("subscribers/makeBid", async (data, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    return await subscribersService.makeBid(ISO, data.orderId, data.signature, data.data);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateBid = createAsyncThunk("subscribers/updateBid", async (data, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    return await subscribersService.updateBid(ISO, data.bidId, data.signature, data.data);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const getPaymentType = createAsyncThunk(
  "subscribers/getPaymentType",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      return await subscribersService.getPaymentType(ISO);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getCurrencies = createAsyncThunk(
  "subscribers/getCurrencies",
  async (branchId, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      return await subscribersService.getCurrencies(ISO, branchId);
    } catch (error) {
      const message = (error.response && error.response.data) || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateSubscriber = createAsyncThunk(
  "subscribers/updateSubscriber",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      return await subscribersService.updateSubscriber(ISO, data.signature, data.data);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const subscribersSlice = createSlice({
  name: "subscribers",
  initialState,
  reducers: {
    resetGetSubscribersLoadboardsOrders: (state) => {
      state.getSubscribersLoadboardsOrdersLoading = true;
      state.getSubscribersLoadboardsOrdersResult = null;
      state.getSubscribersLoadboardsOrdersError = null;
    },
    resetGetSubscribersLoadboardsOrder: (state) => {
      state.getSubscribersLoadboardsOrderLoading = true;
      state.getSubscribersLoadboardsOrderResult = null;
      state.getSubscribersLoadboardsOrderError = null;
    },
    resetGetSubscribersConfirmedOrders: (state) => {
      state.getSubscribersConfirmedOrdersLoading = true;
      state.getSubscribersConfirmedOrdersResult = null;
      state.getSubscribersConfirmedOrdersError = null;
    },
    resetSendOtp: (state) => {
      state.sendOtpLoading = false;
      state.sendOtpResult = null;
      state.sendOtpError = null;
    },
    resetSendOtpAgain: (state) => {
      state.sendOtpAgainLoading = false;
      state.sendOtpAgainResult = null;
      state.sendOtpAgainError = null;
    },
    resetSetNotificationTime: (state) => {
      state.setNotificationTimeLoading = false;
      state.setNotificationTimeResult = null;
      state.setNotificationTimeError = null;
    },
    resetVerify: (state) => {
      state.verifyLoading = false;
      state.verifyResult = null;
      state.verifyError = null;
    },
    resetUnsubscribe: (state) => {
      state.unsubscribeLoading = false;
      state.unsubscribeResult = null;
      state.unsubscribeError = null;
    },
    resetResubscribe: (state) => {
      state.resubscribeLoading = false;
      state.resubscribeResult = null;
      state.resubscribeError = null;
    },
    resetGetProfile: (state) => {
      state.getProfileLoading = true;
      state.getProfileResult = null;
      state.getProfileError = null;
    },
    resetGetPreferences: (state) => {
      state.getPreferencesLoading = true;
      state.getPreferencesResult = null;
      state.getPreferencesError = null;
    },
    resetUpdatePreferences: (state) => {
      state.updatePreferencesLoading = false;
      state.updatePreferencesResult = null;
      state.updatePreferencesError = null;
    },
    resetGetTrailerTypes: (state) => {
      state.getTrailerTypesLoading = true;
      state.getTrailerTypesResult = null;
      state.getTrailerTypesError = null;
    },
    resetGetRoutes: (state) => {
      state.getRoutesLoading = true;
      state.getRoutesResult = null;
      state.getRoutesError = null;
    },
    resetMakeBid: (state) => {
      state.makeBidLoading = false;
      state.makeBidResult = null;
      state.makeBidError = null;
    },
    resetUpdateBid: (state) => {
      state.updateBidLoading = false;
      state.updateBidResult = null;
      state.updateBidError = null;
    },
    resetGetPaymentType: (state) => {
      state.getPaymentTypeLoading = true;
      state.getPaymentTypeResult = null;
      state.getPaymentTypeError = null;
      state.getPaymentTypeSelect = null;
    },
    resetGetCurrencies: (state) => {
      state.getCurrenciesLoading = true;
      state.getCurrenciesResult = null;
      state.getCurrenciesError = null;
      state.getCurrenciesSelect = null;
    },
    resetUpdateSubscriber: (state) => {
      state.updateSubscriberLoading = false;
      state.updateSubscriberResult = null;
      state.updateSubscriberError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSubscribersLoadboardsOrders.pending, (state) => {
        state.getSubscribersLoadboardsOrdersLoading = true;
        state.getSubscribersLoadboardsOrdersResult = null;
        state.getSubscribersLoadboardsOrdersError = null;
      })
      .addCase(getSubscribersLoadboardsOrders.fulfilled, (state, action) => {
        state.getSubscribersLoadboardsOrdersLoading = false;
        state.getSubscribersLoadboardsOrdersResult = action.payload;
        state.getSubscribersLoadboardsOrdersError = null;
      })
      .addCase(getSubscribersLoadboardsOrders.rejected, (state, action) => {
        state.getSubscribersLoadboardsOrdersLoading = false;
        state.getSubscribersLoadboardsOrdersResult = null;
        state.getSubscribersLoadboardsOrdersError = action.payload;
      })

      .addCase(getSubscribersLoadboardsOrder.pending, (state) => {
        state.getSubscribersLoadboardsOrderLoading = true;
        state.getSubscribersLoadboardsOrderResult = null;
        state.getSubscribersLoadboardsOrderError = null;
      })
      .addCase(getSubscribersLoadboardsOrder.fulfilled, (state, action) => {
        state.getSubscribersLoadboardsOrderLoading = false;
        state.getSubscribersLoadboardsOrderResult = action.payload;
        state.getSubscribersLoadboardsOrderError = null;
      })
      .addCase(getSubscribersLoadboardsOrder.rejected, (state, action) => {
        state.getSubscribersLoadboardsOrderLoading = false;
        state.getSubscribersLoadboardsOrderResult = null;
        state.getSubscribersLoadboardsOrderError = action.payload;
      })

      .addCase(getSubscribersConfirmedOrders.pending, (state) => {
        state.getSubscribersConfirmedOrdersLoading = true;
        state.getSubscribersConfirmedOrdersResult = null;
        state.getSubscribersConfirmedOrdersError = null;
      })
      .addCase(getSubscribersConfirmedOrders.fulfilled, (state, action) => {
        state.getSubscribersConfirmedOrdersLoading = false;
        state.getSubscribersConfirmedOrdersResult = action.payload;
        state.getSubscribersConfirmedOrdersError = null;
      })
      .addCase(getSubscribersConfirmedOrders.rejected, (state, action) => {
        state.getSubscribersConfirmedOrdersLoading = false;
        state.getSubscribersConfirmedOrdersResult = null;
        state.getSubscribersConfirmedOrdersError = action.payload;
      })

      .addCase(sendOtp.pending, (state) => {
        state.sendOtpLoading = true;
        state.sendOtpResult = null;
        state.sendOtpError = null;
      })
      .addCase(sendOtp.fulfilled, (state, action) => {
        state.sendOtpLoading = false;
        state.sendOtpResult = action.payload;
        state.sendOtpError = null;
      })
      .addCase(sendOtp.rejected, (state, action) => {
        state.sendOtpLoading = false;
        state.sendOtpResult = null;
        state.sendOtpError = action.payload;
      })

      .addCase(sendOtpAgain.pending, (state) => {
        state.sendOtpAgainLoading = true;
        state.sendOtpAgainResult = null;
        state.sendOtpAgainError = null;
      })
      .addCase(sendOtpAgain.fulfilled, (state, action) => {
        state.sendOtpAgainLoading = false;
        state.sendOtpAgainResult = action.payload;
        state.sendOtpAgainError = null;
      })
      .addCase(sendOtpAgain.rejected, (state, action) => {
        state.sendOtpAgainLoading = false;
        state.sendOtpAgainResult = null;
        state.sendOtpAgainError = action.payload;
      })

      .addCase(setNotificationTime.pending, (state) => {
        state.setNotificationTimeLoading = true;
        state.setNotificationTimeResult = null;
        state.setNotificationTimeError = null;
      })
      .addCase(setNotificationTime.fulfilled, (state, action) => {
        state.setNotificationTimeLoading = false;
        state.setNotificationTimeResult = action.payload;
        state.setNotificationTimeError = null;
      })
      .addCase(setNotificationTime.rejected, (state, action) => {
        state.setNotificationTimeLoading = false;
        state.setNotificationTimeResult = null;
        state.setNotificationTimeError = action.payload;
      })

      .addCase(verify.pending, (state) => {
        state.verifyLoading = true;
        state.verifyResult = null;
        state.verifyError = null;
      })
      .addCase(verify.fulfilled, (state, action) => {
        state.verifyLoading = false;
        state.verifyResult = action.payload;
        state.verifyError = null;
      })
      .addCase(verify.rejected, (state, action) => {
        state.verifyLoading = false;
        state.verifyResult = null;
        state.verifyError = action.payload;
      })

      .addCase(unsubscribe.pending, (state) => {
        state.unsubscribeLoading = true;
        state.unsubscribeResult = null;
        state.unsubscribeError = null;
      })
      .addCase(unsubscribe.fulfilled, (state, action) => {
        state.unsubscribeLoading = false;
        state.unsubscribeResult = action.payload;
        state.unsubscribeError = null;
      })
      .addCase(unsubscribe.rejected, (state, action) => {
        state.unsubscribeLoading = false;
        state.unsubscribeResult = null;
        state.unsubscribeError = action.payload;
      })

      .addCase(resubscribe.pending, (state) => {
        state.resubscribeLoading = true;
        state.resubscribeResult = null;
        state.resubscribeError = null;
      })
      .addCase(resubscribe.fulfilled, (state, action) => {
        state.resubscribeLoading = false;
        state.resubscribeResult = action.payload;
        state.resubscribeError = null;
      })
      .addCase(resubscribe.rejected, (state, action) => {
        state.resubscribeLoading = false;
        state.resubscribeResult = null;
        state.resubscribeError = action.payload;
      })

      .addCase(getProfile.pending, (state) => {
        state.getProfileLoading = true;
        state.getProfileResult = null;
        state.getProfileError = null;
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.getProfileLoading = false;
        state.getProfileResult = action.payload;
        state.getProfileError = null;
      })
      .addCase(getProfile.rejected, (state, action) => {
        state.getProfileLoading = false;
        state.getProfileResult = null;
        state.getProfileError = action.payload;
      })

      .addCase(getPreferences.pending, (state) => {
        state.getPreferencesLoading = true;
        state.getPreferencesResult = null;
        state.getPreferencesError = null;
      })
      .addCase(getPreferences.fulfilled, (state, action) => {
        state.getPreferencesLoading = false;
        state.getPreferencesResult = action.payload;
        state.getPreferencesError = null;
      })
      .addCase(getPreferences.rejected, (state, action) => {
        state.getPreferencesLoading = false;
        state.getPreferencesResult = null;
        state.getPreferencesError = action.payload;
      })

      .addCase(updatePreferences.pending, (state) => {
        state.updatePreferencesLoading = true;
        state.updatePreferencesResult = null;
        state.updatePreferencesError = null;
      })
      .addCase(updatePreferences.fulfilled, (state, action) => {
        state.updatePreferencesLoading = false;
        state.updatePreferencesResult = action.payload;
        state.updatePreferencesError = null;
      })
      .addCase(updatePreferences.rejected, (state, action) => {
        state.updatePreferencesLoading = false;
        state.updatePreferencesResult = null;
        state.updatePreferencesError = action.payload;
      })

      .addCase(getTrailerTypes.pending, (state) => {
        state.getTrailerTypesLoading = true;
        state.getTrailerTypesResult = null;
        state.getTrailerTypesError = null;
      })
      .addCase(getTrailerTypes.fulfilled, (state, action) => {
        state.getTrailerTypesLoading = false;
        state.getTrailerTypesResult = action.payload;
        state.getTrailerTypesError = null;
      })
      .addCase(getTrailerTypes.rejected, (state, action) => {
        state.getTrailerTypesLoading = false;
        state.getTrailerTypesResult = null;
        state.getTrailerTypesError = action.payload;
      })

      .addCase(getRoutes.pending, (state) => {
        state.getRoutesLoading = true;
        state.getRoutesResult = null;
        state.getRoutesError = null;
      })
      .addCase(getRoutes.fulfilled, (state, action) => {
        state.getRoutesLoading = false;
        state.getRoutesResult = action.payload;
        state.getRoutesError = null;
      })
      .addCase(getRoutes.rejected, (state, action) => {
        state.getRoutesLoading = false;
        state.getRoutesResult = null;
        state.getRoutesError = action.payload;
      })

      .addCase(makeBid.pending, (state) => {
        state.makeBidLoading = true;
        state.makeBidResult = null;
        state.makeBidError = null;
      })
      .addCase(makeBid.fulfilled, (state, action) => {
        state.makeBidLoading = false;
        state.makeBidResult = action.payload;
        state.makeBidError = null;
      })
      .addCase(makeBid.rejected, (state, action) => {
        state.makeBidLoading = false;
        state.makeBidResult = null;
        state.makeBidError = action.payload;
      })

      .addCase(updateBid.pending, (state) => {
        state.updateBidLoading = true;
        state.updateBidResult = null;
        state.updateBidError = null;
      })
      .addCase(updateBid.fulfilled, (state, action) => {
        state.updateBidLoading = false;
        state.updateBidResult = action.payload;
        state.updateBidError = null;
      })
      .addCase(updateBid.rejected, (state, action) => {
        state.updateBidLoading = false;
        state.updateBidResult = null;
        state.updateBidError = action.payload;
      })

      .addCase(getPaymentType.pending, (state) => {
        state.getPaymentTypeLoading = true;
        state.getPaymentTypeResult = null;
        state.getPaymentTypeSelect = null;
        state.getPaymentTypeError = null;
      })
      .addCase(getPaymentType.fulfilled, (state, action) => {
        state.getPaymentTypeLoading = false;
        state.getPaymentTypeResult = action.payload;
        state.getPaymentTypeSelect = action.payload.map((item) => ({
          label: item.name,
          value: item,
        }));
        state.getPaymentTypeError = null;
      })
      .addCase(getPaymentType.rejected, (state, action) => {
        state.getPaymentTypeLoading = false;
        state.getPaymentTypeResult = null;
        state.getPaymentTypeSelect = null;
        state.getPaymentTypeError = action.payload;
      })

      .addCase(getCurrencies.pending, (state) => {
        state.getCurrenciesLoading = true;
        state.getCurrenciesResult = null;
        state.getCurrenciesSelect = null;
        state.getCurrenciesError = null;
      })
      .addCase(getCurrencies.fulfilled, (state, action) => {
        state.getCurrenciesLoading = false;
        state.getCurrenciesResult = action.payload;
        state.getCurrenciesSelect = action.payload.map((item) => ({
          value: item,
          label: item.code,
        }));
        state.getCurrenciesError = null;
      })
      .addCase(getCurrencies.rejected, (state, action) => {
        state.getCurrenciesLoading = false;
        state.getCurrenciesResult = null;
        state.getCurrenciesSelect = null;
        state.getCurrenciesError = action.payload;
      })

      .addCase(updateSubscriber.pending, (state) => {
        state.updateSubscriberLoading = true;
        state.updateSubscriberResult = null;
        state.updateSubscriberError = null;
      })
      .addCase(updateSubscriber.fulfilled, (state, action) => {
        state.updateSubscriberLoading = false;
        state.updateSubscriberResult = action.payload;
        state.updateSubscriberError = null;
      })
      .addCase(updateSubscriber.rejected, (state, action) => {
        state.updateSubscriberLoading = false;
        state.updateSubscriberResult = null;
        state.updateSubscriberError = action.payload;
      });
  },
});

export const {
  resetGetSubscribersLoadboardsOrders,
  resetGetSubscribersLoadboardsOrder,
  resetGetProfile,
  resetGetPreferences,
  resetGetRoutes,
  resetGetTrailerTypes,
  resetMakeBid,
  resetGetPaymentType,
  resetGetCurrencies,
  resetSendOtp,
  resetSendOtpAgain,
  resetVerify,
  resetResubscribe,
  resetUnsubscribe,
  resetSetNotificationTime,
  resetUpdatePreferences,
  resetGetSubscribersConfirmedOrders,
  resetUpdateSubscriber,
  resetUpdateBid,
} = subscribersSlice.actions;
export default subscribersSlice.reducer;
