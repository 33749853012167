import React from "react";
import "./ShareNavMobile.scss";

import ShareSidebar from "./../../../ShareSidebar/ShareSidebar";

const ShareNavMobile = ({ openSidebar, setOpenSidebar, items, activeOrder, setActiveOrder }) => {
  return (
    <div className={openSidebar ? "ShareNavMobile open" : "ShareNavMobile"}>
      <div className='ShareNavMobileContainer'>
        <ShareSidebar
          openSidebar={openSidebar}
          setOpenSidebar={setOpenSidebar}
          items={items}
          activeOrder={activeOrder}
          setActiveOrder={setActiveOrder}
        />
      </div>
    </div>
  );
};

export default ShareNavMobile;
