import React, { useEffect, useState } from "react";
import "./PreferencesModal.scss";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { MdOutlineSettingsSuggest, MdPinDrop, MdLocalShipping, MdNearMe } from "react-icons/md";

import { storePreference, updatePreference } from "../../../../store/preferences/preferencesSlice";

import DModal from "../../../Modal/DModal/DModal";
import DSelect from "../../../Form/DSelect/DSelect";
import SelectableButton from "../../../CustomersPartners/Components/SelectableButton/SelectableButton";
import DErrorText from "../../../Form/DErrorText/DErrorText";
import { RiArrowLeftRightFill } from "react-icons/ri";

const PreferencesModal = ({ isOpen, isClose, edit }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { getCountriesSelect } = useSelector((state) => state.settings);
  const { user } = useSelector((state) => state.auth);
  const { getTrailerTypesRoadResult } = useSelector((state) => state.trailerType);
  const { getAllRoutesRoadListResult } = useSelector((state) => state.routes);
  const {
    updatePreferenceLoading,
    storePreferenceLoading,
    storePreferenceError,
    updatePreferenceError,
  } = useSelector((state) => state.preferences);

  const [country, setCountry] = useState();
  const [selectedTrailerTypes, setSelectedTrailerTypes] = useState(
    user?.preferences?.trailer_types?.map((item) => item?.id) || []
  );
  const [selectedRoutes, setSelectedRoutes] = useState(
    user?.preferences?.routes?.map((item) => item?.id) || []
  );

  const submitHandler = () => {
    const data = {
      branch_id: country?.value?.id,
      trailer_types: selectedTrailerTypes,
      routes: selectedRoutes,
    };

    if (edit) {
      dispatch(updatePreference(data));
    } else {
      dispatch(storePreference(data));
    }
  };

  useEffect(() => {
    getCountriesSelect?.find((item) => item?.value?.id === user?.branch_id) &&
      setCountry(getCountriesSelect?.find((item) => item?.value?.id === user?.branch_id));
  }, [getCountriesSelect, user?.branch_id]);

  return (
    <DModal
      isOpen={isOpen}
      isClose={isClose}
      title={t("tms.modals.titles.Select preferences")}
      submitText={t("tms.modals.buttons.Set preferences")}
      icon={<MdOutlineSettingsSuggest />}
      loading={updatePreferenceLoading || storePreferenceLoading}
      onClick={submitHandler}
      status='info'
      btnBackground={"linear-gradient(300deg, #5e2bff 18.14%, #5e2bff 69.4%)"}
    >
      <div id='PreferencesModal'>
        <div
          className='cover'
          style={{ backgroundImage: `url(/assets/images/dashboard/preferencesCover.svg)` }}
        />
        <div className='formItem'>
          <div className='label'>
            <div className='icon'>
              <MdPinDrop />
            </div>
            <div className='title'>{t("tms.modals.labels.Home region")}</div>
          </div>
          <div className='select'>
            <DSelect options={getCountriesSelect} value={country} setValue={setCountry} />
          </div>
        </div>
        <div className='formItem'>
          <div className='label'>
            <div className='icon'>
              <MdLocalShipping />
            </div>
            <div className='title'>{t("tms.modals.labels.Trailer types")}</div>
          </div>
          <div className='trailerTypesList'>
            {getTrailerTypesRoadResult &&
              getTrailerTypesRoadResult?.map((item) =>
                item?.types?.map((trailerType) => (
                  <SelectableButton
                    key={trailerType?.id}
                    item={trailerType}
                    selectedTypes={selectedTrailerTypes}
                    setSelectedTypes={setSelectedTrailerTypes}
                    selected={selectedTrailerTypes?.includes(trailerType?.id)}
                  />
                ))
              )}
            <DErrorText
              error={
                storePreferenceError?.errors?.trailer_types?.[0] ||
                updatePreferenceError?.errors?.trailer_types?.[0]
              }
            />
          </div>
        </div>
        <div className='formItem'>
          <div className='label'>
            <div className='icon'>
              <MdNearMe />
            </div>
            <div className='title'>{t("tms.modals.labels.Preferred routes")}</div>
          </div>
          <div className='destionationHeader'>
            <div className='homeCountry'>{country?.value?.name}</div>
            <div className='icon'>
              <RiArrowLeftRightFill />
            </div>
            <p>{t("tms.modals.others.Selected regions")}</p>
          </div>
          <div className='trailerTypesList'>
            {getAllRoutesRoadListResult &&
              getAllRoutesRoadListResult.map((route) => (
                <SelectableButton
                  key={route?.id}
                  item={route}
                  selectedTypes={selectedRoutes}
                  setSelectedTypes={setSelectedRoutes}
                  selected={selectedRoutes?.includes(route?.id)}
                />
              ))}
            <DErrorText
              error={
                storePreferenceError?.errors?.routes?.[0] ||
                updatePreferenceError?.errors?.routes?.[0]
              }
            />
          </div>
        </div>
      </div>
    </DModal>
  );
};

export default PreferencesModal;
