import React from "react";
import "./ShareDetail.scss";

import ShareDetailContent from "./ShareDetailContent/ShareDetailContent";
import ShareDetailFooter from "./ShareDetailFooter/ShareDetailFooter";

const ShareDetail = ({ order }) => {
  return (
    <div id='ShareDetail'>
      <ShareDetailContent order={order} />
      <ShareDetailFooter order={order} />
    </div>
  );
};

export default ShareDetail;
