import React from "react";
import "./RowField.scss";

const RowField = ({ children, style, className }) => {
  return (
    <tr style={style ? style : {}} className={className} id='tableRowField'>
      {children}
    </tr>
  );
};

export default RowField;
