import React from "react";
import { Routes, Route } from "react-router-dom";

import { ShareOrders } from "../pages/Share/index";

const Share = () => {
  return (
    <Routes>
      <Route path='/order/share/:token' element={<ShareOrders />} />
    </Routes>
  );
};

export default Share;
