import React, { Fragment } from "react";

import Share from "../routes/Share";

const AppShare = () => {
  return (
    <Fragment>
      <div id='S-Layout'>
        <Share />
      </div>
    </Fragment>
  );
};

export default AppShare;
