import React, { Fragment } from "react";
import UnsubscribeComponent from "../../../componentsSubscribers/Unsubscribe/UnsubscribeComponent/UnsubscribeComponent";

const Unsubscribe = () => {
  return (
    <Fragment>
      <div id='Unsubscribe'>
        <div className='wrapper'>
          <UnsubscribeComponent />
        </div> 
      </div>
    </Fragment>
  );
};

export default Unsubscribe;
