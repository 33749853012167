import React from "react";

import "./SubscribeTitleComponent.scss";
const SubscribeTitleComponent = ({ icon, title }) => {
  return (
    <div id='SubscribeTitleComponent'>
      <div className='icon'>{icon}</div>
      <h3 className='title'>{title}</h3>
    </div>
  );
};

export default SubscribeTitleComponent;
