import React from "react";
import "./ShareDetailContentStatusUpdatesNoTracking.scss";

import { useTranslation } from "react-i18next";

const ShareDetailContentStatusUpdatesNoTracking = () => {
  const { t } = useTranslation();

  return (
    <div id='ShareDetailContentStatusUpdatesNoTracking'>
      <div className='ShareDetailContentStatusUpdatesNoTrackingcontent'>
        <div
          className='avatarImage'
          style={{
            backgroundImage: `url(${`/assets/images/dashboard/NoTrackingIllustration.png`})`,
          }}
        ></div>
        <h4>{t("share.Tracking Unavailable at the Moment")}</h4>
        <h6>
          {t(
            "share.Once your shipment is on its way, tracking details will be made available. Thank you for your patience!"
          )}
        </h6>
      </div>
    </div>
  );
};

export default ShareDetailContentStatusUpdatesNoTracking;
