import React from "react";
import "./ShareDetailContentHeader.scss";

import { FaRegUser } from "react-icons/fa";

const ShareDetailContentHeader = ({ order }) => {
  return (
    <div id='ShareDetailContentHeader'>
      <div className='left'>
        <h3>{order?.client?.name || "-"}</h3>
        <div className='salemanager'>
          <FaRegUser />
          <h4>{order?.sale_manager?.name || "-"}</h4>
        </div>
      </div>
      <div className='right'>
        <p>#{order?.id || "-"}</p>
      </div>
    </div>
  );
};

export default ShareDetailContentHeader;
