import React, { Fragment, useState } from "react";
import "./SubscribeBidReqComponent.scss";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import moment from "moment";
import "moment/locale/ka";
import "moment/locale/en-gb";
import "moment/locale/ru";
import "moment/locale/uz";

import { MdMessage } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";
import { MdOutlineTimelapse, MdEdit } from "react-icons/md";
import { useMoney } from "../../../helper/useMoney";

import { useOrderPaymentSelectSubOptions } from "../../../helper/useOrderPaymentSelectSubOptions";

import VerificationModal from "../VerificationModal/VerificationModal";
import SubscribeTitleComponent from "../SubscribeTitleComponent/SubscribeTitleComponent";
import DButton from "../../../componentsDashboard/Form/DButtons/DButton/DButton";
import SubscribersMakeBidModal from "../../SubscribersOrdersList/Modals/SubscribersMakeBidModal/SubscribersMakeBidModal";

const SubscribeBidReqComponent = ({ order }) => {
  const { t } = useTranslation();
  const { token } = useParams();
  const paymentSubOptions = useOrderPaymentSelectSubOptions();

  const { getProfileResult, makeBidLoading, getSubscribersLoadboardsOrderResult } = useSelector(
    (state) => state.subscribers
  );
  const [openVerifyModal, setOpenVerifyModal] = useState(false);
  const [openMakeBidModal, setOpenMakeBidModal] = useState(false);
  const [openEditMakeBidModal, setOpenEditMakeBidModal] = useState(false);

  const payMethodSub =
    paymentSubOptions?.find(
      (payment) => payment?.value?.id === order?.bid?.payment_method?.option_type
    )?.label || null;

  const cargo = order?.bid;
  const cargoPrice = useMoney(cargo?.amount, cargo?.currency?.symbol, 0);
  const prePayment = useMoney(
    cargo?.payment_method?.amount || 0,
    cargo?.payment_method?.currency?.symbol || "$",
    0
  );

  const { ISO } = useSelector((state) => state.lang);
  moment.locale(ISO);

  const formattedDate = moment(cargo?.bid?.created_at).format("DD MMMM YYYY");

  return (
    <Fragment>
      {getSubscribersLoadboardsOrderResult && !makeBidLoading && (
        <div id='SubscribeBidReqComponent'>
          <div className='subscribeBidReqCard'>
            <div className='CardHeader'>
              <SubscribeTitleComponent
                title={
                  order?.bid && order?.status?.id >= 3
                    ? t("subscribers.details.Accepted")
                    : order?.bid && order?.status?.id < 3
                    ? t("subscribers.details.Offer sent")
                    : t("subscribers.details.Bid Request")
                }
                icon={
                  order?.bid && order?.status?.id >= 3 ? (
                    <FaCheckCircle style={{ color: "#287D3C" }} />
                  ) : order?.bid && order?.status?.id < 3 ? (
                    <FaCheckCircle style={{ color: "#287D3C" }} />
                  ) : (
                    <MdMessage />
                  )
                }
              />
            </div>
            {order?.bid ? (
              <div className='CardBody cardBodyOffer'>
                <div className='MyOffer'>
                  <div className='offerTop'>
                    <h2>{t("subscribers.details.My offer")}</h2>
                    <div className='SentTime'>
                      <span>{t("subscribers.details.Sent")}</span>: {formattedDate}
                    </div>
                  </div>
                  <div className={`offerBoxBottom ${order?.status?.id === 3 ? "boxRelative" : ""}`}>
                    <div className='BoxTop'>
                      <div className='imgAndPaid'>
                        <div
                          className='img'
                          style={{
                            backgroundImage: `url(${`/assets/images/subscribers/59.png`})`,
                            width: "48px",
                            height: "48px",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                          }}
                        ></div>
                        <div className='PrePaid'>
                          <h2>{cargoPrice}</h2>
                          <p>{order?.bid?.payment_method?.type?.name}</p>
                        </div>
                      </div>
                      {order?.status?.id < 3 && (
                        <div className='AwaitingBox'>
                          <div className='icon'>
                            <MdOutlineTimelapse />
                          </div>
                          <p>{t("subscribers.details.Awaiting")}</p>
                        </div>
                      )}
                    </div>
                    <div className='BoxBottom'>
                      <div className='InfoItemLeft'>
                        {cargo?.payment_method?.amount && (
                          <h4>{t("subscribers.details.Pre payment")}</h4>
                        )}
                        {order?.bid?.payment_method?.days && (
                          <h4>{t("subscribers.details.Post payment terms")}</h4>
                        )}
                        {payMethodSub && <h4>{t("subscribers.details.Condition")}</h4>}
                      </div>
                      <div className='InfoItemRight'>
                        {cargo?.payment_method?.amount && <h2>{prePayment}</h2>}
                        {order?.bid?.payment_method?.days && (
                          <h2>
                            {order?.bid?.payment_method?.days} {t("subscribers.details.days")}
                          </h2>
                        )}
                        {payMethodSub && <h2>{payMethodSub}</h2>}
                      </div>
                      {order?.status?.id < 3 ? (
                        <div
                          className='updateBidLink'
                          onClick={() => setOpenEditMakeBidModal(true)}
                        >
                          <div className='icon'>
                            <MdEdit />
                          </div>
                          <p>{t("subscribers.details.Update bid")}</p>
                        </div>
                      ) : (
                        <div className='acceptedTextBox'>
                          <div className='icon'>
                            <FaCheckCircle style={{ color: "#287D3C", fontSize: "24px" }} />
                          </div>
                          <div className='text'>
                            {t("subscribers.details.Your offer accepted by carrier")}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className='CardBody CardBodyMakeBid'>
                <div
                  className='img-01'
                  style={{
                    backgroundImage: `url(${`/assets/images/subscribers/Telecommuting-pana-1.svg`})`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    width: "276px",
                    height: "184px",
                  }}
                ></div>
                <div className='text'>
                  {t(
                    "subscribers.details.Place your bid and we'll process it and get back to you with a decision!"
                  )}
                </div>
                <DButton
                  title={t("subscribers.details.Make a Bid")}
                  onClick={() =>
                    getProfileResult?.phone_verified_at
                      ? setOpenMakeBidModal(true)
                      : setOpenVerifyModal(true)
                  }
                />
              </div>
            )}
          </div>
        </div>
      )}
      {openVerifyModal && (
        <VerificationModal
          isOpen={openVerifyModal}
          isClose={() => setOpenVerifyModal(false)}
          setOpenMakeBidModal={setOpenMakeBidModal}
          setOpenVerifyModal={setOpenVerifyModal}
        />
      )}
      {openMakeBidModal && (
        <SubscribersMakeBidModal
          isOpen={openMakeBidModal}
          isClose={() => setOpenMakeBidModal(false)}
          item={order}
          token={token}
        />
      )}
      {openEditMakeBidModal && (
        <SubscribersMakeBidModal
          isOpen={openEditMakeBidModal}
          isClose={() => setOpenEditMakeBidModal(false)}
          item={order}
          token={token}
          edit
        />
      )}
    </Fragment>
  );
};

export default SubscribeBidReqComponent;
