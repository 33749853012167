import React from "react";
import "./FilterPlainToggleButton.scss";

import { useSearchParams } from "react-router-dom";

import { MdCancel } from "react-icons/md";

const FilterPlainToggleButton = ({ text, isActive, setIsActive, searchParamName }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const handleClick = () => {
    setIsActive(!isActive);
    if (searchParamName) {
      searchParams.set(searchParamName, !isActive);
      setSearchParams(searchParams);
    }
  };

  return (
    <div
      id='FilterPlainToggleButton'
      className={`${isActive ? "active" : ""}`}
      onClick={handleClick}
    >
      <p>{text}</p>
      {isActive && (
        <div className='icon'>
          <MdCancel />
        </div>
      )}
    </div>
  );
};

export default FilterPlainToggleButton;
