import React, { Fragment, useState } from "react";
import "./LoadBoardsActionField.scss";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { MdEdit } from "react-icons/md";

import { useMoney } from "../../../../../helper/useMoney";

import DButton from "../../../DButtons/DButton/DButton";
import DTextButton from "../../../DButtons/DTextButton/DTextButton";
import SubscribersMakeBidModal from "../../../../../componentsSubscribers/SubscribersOrdersList/Modals/SubscribersMakeBidModal/SubscribersMakeBidModal";
import VerificationModal from "../../../../../componentsSubscribers/SubscribersDetails/VerificationModal/VerificationModal";

const LoadBoardsActionField = ({ item }) => {
  const { t } = useTranslation();
  const { token } = useParams();

  const { getProfileResult } = useSelector((state) => state.subscribers);

  const [openMakeBidModal, setOpenMakeBidModal] = useState(false);
  const [openEditMakeBidModal, setOpenEditMakeBidModal] = useState(false);
  const [openVerifyModal, setOpenVerifyModal] = useState(false);

  const formattedBid = useMoney(item?.bid?.amount, item?.bid?.currency?.symbol);

  return (
    <Fragment>
      <td id='LoadBoardsActionField'>
        <div className='LoadBoardsActionFieldcontent'>
          {item?.bid ? (
            <div className='bidContainer'>
              <p>
                {t("subscribers.tableList.You offered")}: <span>{formattedBid}</span>
              </p>
              <DTextButton title={<MdEdit />} onClick={() => setOpenEditMakeBidModal(true)} />
            </div>
          ) : (
            <DButton
              title={t("subscribers.tableList.Make a bid")}
              onClick={() =>
                getProfileResult?.phone_verified_at
                  ? setOpenMakeBidModal(true)
                  : setOpenVerifyModal(true)
              }
            />
          )}
        </div>
      </td>
      {openMakeBidModal && (
        <SubscribersMakeBidModal
          isOpen={openMakeBidModal}
          isClose={() => setOpenMakeBidModal(false)}
          token={token}
          item={item}
        />
      )}
      {openEditMakeBidModal && (
        <SubscribersMakeBidModal
          isOpen={openEditMakeBidModal}
          isClose={() => setOpenEditMakeBidModal(false)}
          token={token}
          item={item}
          edit
        />
      )}
      {openVerifyModal && (
        <VerificationModal
          isOpen={openVerifyModal}
          isClose={() => setOpenVerifyModal(false)}
          setOpenMakeBidModal={setOpenMakeBidModal}
          setOpenVerifyModal={setOpenVerifyModal}
        />
      )}
    </Fragment>
  );
};

export default LoadBoardsActionField;
