import React from "react";
import "./LoadBoardsTrailerRequirementField.scss";

const LoadBoardsTrailerRequirementField = ({ item }) => {
  return (
    <td id='LoadBoardsTrailerRequirementField'>
      <div className='LoadBoardsTrailerRequirementFieldcontent'>
        <p>
          {item?.cargos?.[0]?.trailerType?.name}, {item?.cargos?.[0]?.trailersize?.label}
        </p>
      </div>
    </td>
  );
};

export default LoadBoardsTrailerRequirementField;
