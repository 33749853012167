import React, { useEffect, useState } from "react";
import "./EditPhoneModal.scss";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { FaPen } from "react-icons/fa";
import {
  resetSendOtp,
  resetSendOtpAgain,
  resetVerify,
  sendOtp,
  sendOtpAgain,
  verify,
} from "../../../../store/subscribers/subscribersSlice";

import DModal from "../../../../componentsDashboard/Modal/DModal/DModal";
import DPhoneInput from "../../../../componentsDashboard/Form/DPhoneInput/DPhoneInput";
import SendAgain from "../../../../componentsLanding/Auth&Password/Components/SendAgain/SendAgain";
import OTP from "../../../../componentsLanding/Form/OTP/OTP";

const EditPhoneModal = ({ isOpen, isClose, token }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    sendOtpResult,
    sendOtpLoading,
    sendOtpError,
    getProfileResult,
    verifyResult,
    verifyLoading,
    verifyError,
    sendOtpAgainResult,
    sendOtpAgainLoading,
  } = useSelector((state) => state.subscribers);
  const { getPhoneIndexesResult } = useSelector((state) => state.settings);

  const [step, setStep] = useState(1);

  const [phone, setPhone] = useState("");
  const [phoneIndex, setPhoneIndex] = useState(null);

  const [otp, setOtp] = useState("");

  useEffect(() => {
    if (getPhoneIndexesResult && getProfileResult) {
      const currentPhoneIndex = getProfileResult?.phone_index?.phone_index
        ? getPhoneIndexesResult?.find(
            (index) => index?.phone_index === getProfileResult?.phone_index?.phone_index
          )
        : getPhoneIndexesResult?.find(
            (index) => index?.phone_index === getProfileResult?.registration_country?.phone_index
          );
      setPhoneIndex({
        value: currentPhoneIndex,
        label: (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: "15px",
                height: "15px",
                backgroundImage: `url(${currentPhoneIndex?.flag})`,
                backgroundPosition: "cover",
                backgroundRepeat: "no-repeat",
                marginRight: "5px",
              }}
            ></div>
            <p>{currentPhoneIndex?.phone_index}</p>
          </div>
        ),
      });
    }
  }, [getPhoneIndexesResult, getProfileResult]);

  useEffect(() => {
    if (getProfileResult) {
      setPhone(getProfileResult?.phone);
    }
  }, [getProfileResult]);

  const handleSendOtp = () => {
    const data = {
      signature: token,
      data: {
        phone: phone,
        phone_index_id: phoneIndex?.value?.id,
        type: "phone",
      },
    };
    dispatch(sendOtp(data));
  };

  const handleSendOtpAgain = () => {
    const data = {
      signature: token,
      data: {
        phone: phone,
        phone_index_id: phoneIndex?.value?.id,
        type: "phone",
      },
    };
    dispatch(sendOtpAgain(data));
  };

  const handleVerifyPhone = () => {
    const verifyData = {
      signature: token,
      data: {
        code: otp,
        type: "phone",
      },
    };
    dispatch(verify(verifyData));
  };

  useEffect(() => {
    if (sendOtpResult) {
      setStep(2);
      dispatch(resetSendOtp());
    }
  }, [dispatch, sendOtpResult]);

  useEffect(() => {
    if (verifyResult) {
      isClose();
      dispatch(resetVerify());
    }
  }, [dispatch, isClose, verifyResult]);

  return (
    <DModal
      isOpen={isOpen}
      isClose={isClose}
      title={t("subscribers.profile.Change phone number")}
      submitText={t("subscribers.profile.Verify & Update")}
      icon={<FaPen />}
      onClick={step === 1 ? handleSendOtp : handleVerifyPhone}
      loading={step === 1 ? sendOtpLoading : verifyLoading}
    >
      <div id='EditPhoneModal'>
        {step === 1 && (
          <>
            <p>
              {t(
                "subscribers.profile.To update your mobile number, enter your new phone number below. You’ll receive a verification code in the next step."
              )}
            </p>
            <DPhoneInput
              id='CustomerPhone'
              value={phone}
              setValue={setPhone}
              indexValue={phoneIndex}
              setIndexValue={setPhoneIndex}
              placeholder={t("tms.modals.placeholder.Enter phone number")}
              label={t("tms.modals.labels.Phone number")}
              error={
                sendOtpError?.errors?.phone?.[0] ||
                sendOtpError?.errors?.phone_index_id?.[0] ||
                null
              }
            />
          </>
        )}
        {step === 2 && (
          <div className='step2Container'>
            <p>
              {t(
                "subscribers.profile.We’ve sent a 4-digit code to {{phone}}. Enter it below to verify your number.",
                { phone: `+${phoneIndex?.value?.phone_index} ${phone}` }
              )}
            </p>
            <OTP value={otp} setValue={setOtp} count={4} error={verifyError?.error} />
            <SendAgain
              title={t("subscribers.details.Send again")}
              onClick={handleSendOtpAgain}
              result={sendOtpAgainResult}
              loading={sendOtpAgainLoading}
              reset={() => dispatch(resetSendOtpAgain())}
            />
          </div>
        )}
      </div>
    </DModal>
  );
};

export default EditPhoneModal;
