import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { getOrderTracking } from "../../../../../../store/trackerManager/trackerManagerSlice";

import OceanProgressBar from "../../../../../../componentsDashboard/Orders/ShipperTracking/TrackingDistanceData/OceanProgressBar/OceanProgressBar";

const ShareOceanprogressContainer = ({ order }) => {
  const dispatch = useDispatch();

  const isSubscribed = order?.transports?.find((i) => i?.subscribed);

  useEffect(() => {
    if (isSubscribed) {
      dispatch(getOrderTracking(order?.id));
    }
  }, [dispatch, isSubscribed, order?.id]);

  return <OceanProgressBar order={order} />;
};

export default ShareOceanprogressContainer;
