import React, { Fragment, useEffect, useState } from "react";
import "./SubscribersFiltersMobile.scss";

import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { FaFilter } from "react-icons/fa";

import PreferencesFilter from "../../../componentsDashboard/LoadBoards/PreferencesFilter/PreferencesFilter";
import SubscribersFiltersMobileFiltersModal from "./SubscribersFiltersMobileFiltersModal/SubscribersFiltersMobileFiltersModal";
import SortSelect from "../../../componentsDashboard/FilterForms/SortSelect/SortSelect";

const SubscribersFiltersMobile = ({
  preferences,
  setPreferences,
  handlePreferenceFilterSelect,
  handlePreferenceFilterDeselect,
  sort,
  setSort,
  pickupDropoffInGeorgia,
  setPickupDropoffInGeorgia,
  pickupNext3Days,
  setPickupNext3Days,
  selectedRoutes,
  setSelectedRoutes,
  routesOptions,
  selectedTrailers,
  setSelectedTrailers,
  trailersOptions,
  pickupCountry,
  setPickupCountry,
  dropoffCountry,
  setDropoffCountry,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  onClear,
}) => {
  const { t } = useTranslation();
  const { getPreferencesResult } = useSelector((state) => state.subscribers);
  const { token } = useParams();

  const sortOptions = [
    {
      label: t("subscribers.board.Weight"),
      options: [
        {
          value: "1",
          label: t("subscribers.board.Low to high"),
          data: { sortBy: "weight_asc" },
        },
        {
          value: "2",
          label: t("subscribers.board.High to low"),
          data: { sortBy: "weight_desc" },
        },
      ],
    },
    {
      label: t("subscribers.board.Pick up date"),

      options: [
        {
          value: "3",
          label: t("subscribers.board.Earliest"),
          data: { sortBy: "pickup_date_asc" },
        },
        {
          value: "4",
          label: t("subscribers.board.Latest"),
          data: { sortBy: "pickup_date_desc" },
        },
      ],
    },
  ];

  const [openMobileFiltersModal, setOpenMobileFiltersModal] = useState(false);
  const [sortMobile, setSortMobile] = useState(null);

  useEffect(() => {
    setSort(sortMobile?.data?.sortBy);
  }, [setSort, sortMobile]);

  return (
    <Fragment>
      <div id='SubscribersFiltersMobile'>
        <div className='leftContainer'>
          {setPreferences && (
            <PreferencesFilter
              preferences={preferences}
              setPreferences={setPreferences}
              withoutEdit
              handleClick={
                preferences ? handlePreferenceFilterDeselect : handlePreferenceFilterSelect
              }
              orderCount={getPreferencesResult?.order_count}
            />
          )}
          <div className='filtersButton' onClick={() => setOpenMobileFiltersModal(true)}>
            <div className='icon'>
              <FaFilter />
            </div>
            <p>{t("subscribers.board.Filters")}</p>
          </div>
        </div>
        <div className='rightContainer'>
          <SortSelect value={sortMobile} setValue={setSortMobile} options={sortOptions} />
        </div>
      </div>
      {openMobileFiltersModal && (
        <SubscribersFiltersMobileFiltersModal
          isOpen={openMobileFiltersModal}
          isClose={() => setOpenMobileFiltersModal(false)}
          onClear={onClear}
          handleFilter={handlePreferenceFilterSelect}
          preferences={preferences}
          setPreferences={setPreferences}
          handlePreferenceFilterDeselect={handlePreferenceFilterDeselect}
          handlePreferenceFilterSelect={handlePreferenceFilterSelect}
          pickupDropoffInGeorgia={pickupDropoffInGeorgia}
          setPickupDropoffInGeorgia={setPickupDropoffInGeorgia}
          pickupNext3Days={pickupNext3Days}
          setPickupNext3Days={setPickupNext3Days}
          selectedRoutes={selectedRoutes}
          setSelectedRoutes={setSelectedRoutes}
          routesOptions={routesOptions}
          selectedTrailers={selectedTrailers}
          setSelectedTrailers={setSelectedTrailers}
          trailersOptions={trailersOptions}
          pickupCountry={pickupCountry}
          setPickupCountry={setPickupCountry}
          dropoffCountry={dropoffCountry}
          setDropoffCountry={setDropoffCountry}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          token={token}
        />
      )}
    </Fragment>
  );
};

export default SubscribersFiltersMobile;
